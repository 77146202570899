"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.removeWatcher = exports.checkScroll = exports.isVideoModeWithScroll = exports.checkSurveyHasScroll = void 0;
const effects_1 = require("redux-saga/effects");
const root_1 = require("src/utils/root");
const actions_1 = require("../actions");
const selectors_1 = require("./selectors");
const consts_1 = require("./consts");
function* checkSurveyHasScroll() {
    const container = yield (0, effects_1.call)(root_1.getRoot);
    const footer = document.querySelector('.footer');
    const footerHeight = footer ? footer.clientHeight : 0;
    if (container.scrollHeight > container.clientHeight + footerHeight) {
        return true;
    }
    return false;
}
exports.checkSurveyHasScroll = checkSurveyHasScroll;
function* isVideoModeWithScroll() {
    const { displayMode } = yield (0, effects_1.select)(selectors_1.getConfig);
    const surveyHasScroll = yield (0, effects_1.call)(checkSurveyHasScroll);
    const isVideo = displayMode === 'video';
    return isVideo && surveyHasScroll;
}
exports.isVideoModeWithScroll = isVideoModeWithScroll;
function* checkScroll() {
    const hasScroll = yield (0, effects_1.call)(isVideoModeWithScroll);
    if (hasScroll) {
        yield (0, effects_1.put)((0, actions_1.detectScroll)());
    }
}
exports.checkScroll = checkScroll;
function* removeWatcher(watcher) {
    yield (0, effects_1.cancel)(watcher);
}
exports.removeWatcher = removeWatcher;
function* router() {
    const watcher = yield (0, effects_1.takeEvery)(consts_1.SCROLL_CHECK_ACTIONS, checkScroll);
    yield (0, effects_1.takeLatest)('DETECTED_SCROLL_ON_SURVEY', removeWatcher, watcher);
}
exports.router = router;
