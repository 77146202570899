"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hexToRgba = exports.hexToRgb = void 0;
const HEX_REGEX = /^([a-f\d])([a-f\d])([a-f\d])$/i;
function hexToRgb(hex) {
    return (hex[0] === '#' ? hex.substring(1) : hex)
        // eslint-disable-next-line max-params
        .replace(HEX_REGEX, (_, r, g, b) => {
        return `${r}${r}${g}${g}${b}${b}`;
    })
        .match(/.{2}/g)
        .map(x => parseInt(x, 16));
}
exports.hexToRgb = hexToRgb;
function hexToRgba(hex, transparency = 1) {
    const [r, g, b] = hexToRgb(hex);
    return `rgba(${r}, ${g}, ${b}, ${transparency})`;
}
exports.hexToRgba = hexToRgba;
