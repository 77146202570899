"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.handleDims = exports.getContainerDims = void 0;
const effects_1 = require("redux-saga/effects");
const root_1 = require("src/utils/root");
const actions_1 = require("../actions");
function* getContainerDims() {
    const container = yield (0, effects_1.call)(root_1.getRoot);
    return {
        height: container.scrollHeight,
        width: container.scrollWidth
    };
}
exports.getContainerDims = getContainerDims;
function* handleDims() {
    const dims = yield (0, effects_1.call)(getContainerDims);
    yield (0, effects_1.put)((0, actions_1.setDims)(dims));
}
exports.handleDims = handleDims;
function* router() {
    yield (0, effects_1.throttle)(100, 'RESIZE', handleDims);
}
exports.router = router;
