"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileScaleStyleString(branding) {
    const { scale = {} } = branding;
    const { block: { count2maxWidth, count3maxWidth, innerPosition, maxWidth, position } = {}, padding: { inline: { ifMany, ifFew } = {} } = {}, labels: { color, fontSize: labelsFontSize, lineHeight: labelsLineHeight, margin: { bottom, top } = {} } = {}, option: { bg, border: { color: borderColor, hover: { color: borderHoverColor } = {}, radius, size, style } = {}, fontSize, focusVisible: { border: { color: focusVisibleBorderColor, } = {} } = {}, color: optionColor, hoverBg, hoverColor, lineHeight, width, mobile: { fontSize: fontSizeIfFew, many: { fontSize: fontSizeIfMany, labelWidth, lineHeight: lineHeightIfMany, marginBottom } = {} } = {}, } = {} } = scale;
    return `{
        ${count2maxWidth ? `--scale-options-count-2-max-width: ${count2maxWidth};` : ''}
        ${count3maxWidth ? `--scale-options-count-3-max-width: ${count3maxWidth};` : ''}
        ${maxWidth ? `--scale-options-max-width: ${maxWidth};` : ''}
        ${innerPosition ? `--scale-options-inner-position: ${innerPosition};` : ''}
        ${position ? `--scale-options-position: ${position};` : ''}
        ${borderColor ? `--scale-option-border-color: ${borderColor};` : ''}
        ${borderHoverColor ? `--scale-option-border-hover-color: ${borderHoverColor};` : ''}
        ${radius === undefined ? '' : `--scale-option-border-radius: ${radius};`}
        ${focusVisibleBorderColor ? `--scale-option-focus-visible-border-color: ${focusVisibleBorderColor};` : ''}
        ${size === undefined ? '' : `--scale-option-border-width: ${size};`}
        ${style ? `--scale-option-border-style: ${style};` : ''}
        ${color ? `--scale-labels-color: ${color};` : ''}
        ${labelsFontSize ? `--scale-labels-font-size: ${labelsFontSize};` : ''}
        ${labelsLineHeight ? `--scale-labels-line-height: ${labelsLineHeight};` : ''}
        ${bottom ? `--scale-labels-margin-bottom: ${bottom};` : ''}
        ${top ? `--scale-labels-margin-top: ${top};` : ''}
        ${fontSizeIfFew ? `--scale-mobile-few-options-font-size: ${fontSizeIfFew};` : ''}
        ${fontSizeIfMany ? `--scale-mobile-many-options-font-size: ${fontSizeIfMany};` : ''}
        ${labelWidth ? `--scale-mobile-many-options-label-width: ${labelWidth};` : ''}
        ${lineHeightIfMany ? `--scale-mobile-many-options-label-line-height: ${lineHeightIfMany};` : ''}
        ${marginBottom ? `--scale-mobile-many-options-margin-bottom: ${marginBottom};` : ''}
        ${bg ? `--scale-option-bg: ${bg};` : ''}
        ${fontSize ? `--scale-option-font-size: ${fontSize};` : ''}
        ${optionColor ? `--scale-option-color: ${optionColor};` : ''}
        ${hoverBg ? `--scale-option-hover-bg: ${hoverBg};` : ''}
        ${hoverColor ? `--scale-option-hover-color: ${hoverColor};` : ''}
        ${lineHeight ? `--scale-option-line-height: ${lineHeight};` : ''}
        ${ifMany ? `--scale-many-options-padding-inline: ${ifMany};` : ''}
        ${ifFew ? `--scale-options-padding-inline: ${ifFew};` : ''}
        ${width ? `
            --scale-option-width: ${width};
            --scale-option-flex: ${width === '100%' ? 1 : 0};
        ` : ''}
    }`;
}
exports.default = compileScaleStyleString;
