"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .tooltip__wrapper {
        position: relative;
    }

    .tooltip__text {
        background: var(--tooltip-bg);
        border-color: var(--tooltip-border-color);
        border-radius: var(--tooltip-border-radius);
        border-width: var(--tooltip-border-width);
        border-style: solid;
        box-shadow: var(--tooltip-shadow);
        color: var(--tooltip-text-color);
        font-size: var(--tooltip-font-size);
        font-weight: var(--tooltip-font-weight);
        left: 50%;
        line-height: var(--tooltip-line-height);
        padding: var(--tooltip-padding);
        position: absolute;
        right: auto;
        top: var(--tooltip-top);
        transform: translateX(-50%);
        visibility: hidden;
        z-index: 200;
    }

    .tooltip__wrapper_showPopup.tooltip__wrapper_isMobile .tooltip__text,
    .tooltip__wrapper:hover .tooltip__text {
        visibility: visible;
    }
`;
