"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .app_theme_ugc-cab
    {
        padding: 0;

        color: #fff;
        background: #222;

        box-sizing: border-box;

        -webkit-font-smoothing: antialiased;

        &,
        & .app__body
        {
            display: block;
        }

        & .pages
        {
            display: block;
        }

        & .page__questions
        {
            overflow: hidden;
        }

        & .markdown h1
        {
            margin: 0.5em 0;

            font-size: 28px;
            line-height: 34px;
            font-weight: bold;
        }

        & .markdown h1
        {
            margin: 0.5em 0 0;
        }

        & .final
        {
            height: 100%;
        }
    }
`;
