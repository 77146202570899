"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function compileWidgetStyleString(branding) {
    const { widget = {} } = branding;
    const { fontSize, fontWeight, lineHeight } = widget;
    return `
        {
            ${fontWeight ? `--widget-font-weight: ${fontWeight};` : ''}
            ${fontSize ? `--widget-font-size: ${fontSize};` : ''}
            ${lineHeight ? `--widget-line-height: ${lineHeight};` : ''}
        }
    `;
}
exports.default = compileWidgetStyleString;
