"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    &.plug {
        display: flex;
        justify-content: flex-end;
        font-size: var(--input-restriction-font-size);
        font-weight: var(--input-restriction-font-weight);
        color: rgba(26, 43, 77, 0.6);
        line-height: var(--input-restriction-line-height);
        bottom: var(--input-restriction-bottom);
        position: absolute;
        right: var(--input-restriction-right);
    }

    .plug__counter_lengthExceeds {
        color: var(--error-color);
    }

    @media (max-width: 640px) {
        &.plug {
            bottom: var(--input-restriction-mobile-bottom);
            right: var(--input-restriction-mobile-right);
        }
    }
`;
