"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.handleClickTarget = void 0;
const redux_saga_1 = require("redux-saga");
const effects_1 = require("redux-saga/effects");
const actions_1 = require("src/redux/actions");
const selectors_1 = require("./selectors");
// При клике на область в картинке
function* handleClickTarget(action) {
    const currentPageQuestions = yield (0, effects_1.select)(selectors_1.getCurrentPageNodes);
    if (action.value.isTarget) {
        if (currentPageQuestions.length > 1) {
            yield (0, redux_saga_1.delay)(300);
            yield (0, effects_1.put)((0, actions_1.scrollToQuestion)(currentPageQuestions[1]));
        }
        else {
            yield (0, effects_1.put)((0, actions_1.clickNextPage)());
        }
    }
}
exports.handleClickTarget = handleClickTarget;
function* router() {
    yield (0, effects_1.takeLatest)('QUESTION_CLICKMAP_IMAGE_CLICK', handleClickTarget);
}
exports.router = router;
