"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lang = (state = 'ru', action) => {
    switch (action.type) {
        case 'SURVEY_SET_INITED':
        case 'SURVEY_LOADED_FROM_CONFIG': {
            const { lang } = action.data;
            return lang;
        }
        default: {
            return state;
        }
    }
};
exports.default = lang;
