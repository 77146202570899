"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.handler = exports.setData = exports.removeData = exports.LIST_HANDLED_ACTIONS = void 0;
const effects_1 = require("redux-saga/effects");
const survey_1 = require("src/utils/survey");
const selectors_1 = require("./selectors");
exports.LIST_HANDLED_ACTIONS = [
    'SURVEY_GET_SUCCESS',
    'SURVEY_SEND_ANSWER_SUCCESS',
    'MOVE_NEXT_PAGE',
    'SURVEY_FINISH_SUCCESS',
    'SURVEY_SCREENOUT_SUCCESS',
    'LANG_SET'
];
function removeData(lsClient, interviewKey) {
    if (lsClient) {
        lsClient.del(interviewKey);
    }
}
exports.removeData = removeData;
function setData(lsClient, data, interviewKey) {
    if (lsClient) {
        lsClient.set(interviewKey, data);
    }
}
exports.setData = setData;
// Если текущая страница -1 ("Спасибо" после окончания опроса),
// то надо очистить LS, иначе сохраняем весь стейт
function* handler(lsClient) {
    const currPage = yield (0, effects_1.select)(selectors_1.getCurrentPage);
    const { pathname, search } = yield (0, effects_1.select)(selectors_1.getLocation);
    const interviewKey = yield (0, effects_1.call)(survey_1.getSurveyInterviewKey, { pathname, search });
    if (currPage === -1) {
        yield (0, effects_1.call)(removeData, lsClient, interviewKey);
    }
    else {
        const state = yield (0, effects_1.select)(selectors_1.getState);
        yield (0, effects_1.call)(setData, lsClient, state, interviewKey);
    }
}
exports.handler = handler;
function* router(lsClient) {
    yield (0, effects_1.takeEvery)(exports.LIST_HANDLED_ACTIONS, handler, lsClient);
}
exports.router = router;
