"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileMarkdownStyleString(branding) {
    const { markdown = {} } = branding;
    const { fontSize, fontWeight, h1FontSize, h1FontWeight, h1LineHeight, h2FontSize, h2FontWeight, h2LineHeight, h3FontSize, h3LineHeight, href, lineHeight } = markdown;
    return `
        {
            ${fontSize ? `--markdown-font-size: ${fontSize};` : ''}
            ${fontWeight ? `--markdown-font-weight: ${fontWeight};` : ''}
            ${lineHeight ? `--markdown-line-height: ${lineHeight};` : ''}
            ${h1FontSize ? `--markdown-h1-font-size: ${h1FontSize};` : ''}
            ${h1FontWeight ? `--markdown-h1-font-weight: ${h1FontWeight};` : ''}
            ${h1LineHeight ? `--markdown-h1-line-height: ${h1LineHeight};` : ''}
            ${h2FontSize ? `--markdown-h2-font-size: ${h2FontSize};` : ''}
            ${h2FontWeight ? `--markdown-h2-font-weight: ${h2FontWeight};` : ''}
            ${h2LineHeight ? `--markdown-h2-line-height: ${h2LineHeight};` : ''}
            ${h3FontSize ? `--markdown-h3-font-size: ${h3FontSize};` : ''}
            ${h3LineHeight ? `--markdown-h3-line-height: ${h3LineHeight};` : ''}
            ${href ? `
                --markdown-href-color: ${href.color};
                --markdown-href-hover-color: ${href.hoverColor};
                ${href.textDecoration ? `--markdown-href-text-decoration: ${href.textDecoration}` : ''};
            ` : ''}
        }
    `;
}
exports.default = compileMarkdownStyleString;
