"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RUTUBE_ORIGIN = void 0;
const common_components_1 = require("@yandex-int/pythia-libs/lib/common-components");
exports.RUTUBE_ORIGIN = 'https://rutube.ru';
function getRutubeToYtEvent({ type: eventName, data }) {
    if (['player:playComplete', 'player:error'].includes(eventName)) {
        return 0;
    }
    if (eventName === 'player:playStart') {
        return 1;
    }
    if (eventName === 'player:changeState' && data.state === 'playing') {
        return 1;
    }
    if (eventName === 'player:changeState' && data.state === 'paused') {
        return 2;
    }
    if (['player:ready', 'player:currentTime'].includes(eventName)) {
        return 3;
    }
}
// простейший плеер для роликов рутуба, апи приближенно к https://github.com/gajus/youtube-player#readme
function RutubePlayer(frame) {
    let onReady = () => { };
    let onStateChange = () => { };
    let lastEventName;
    let currentTime = 0;
    let duration;
    window.addEventListener('message', messageHandler);
    function sendMessage(data) {
        var _a;
        (_a = frame.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage(JSON.stringify(data), '*');
    }
    function checkStateChanged(videoData) {
        const eventName = videoData.type;
        if (eventName !== 'player:currentTime' &&
            (lastEventName === eventName || lastEventName === 'player:playComplete')) {
            return;
        }
        lastEventName = eventName;
        onStateChange({ data: getRutubeToYtEvent(videoData) });
    }
    /* eslint max-statements:0 */
    function messageHandler(event) {
        if (event.origin === exports.RUTUBE_ORIGIN) {
            const videoData = (0, common_components_1.parsePostMessageData)(event.data);
            checkStateChanged(videoData);
            if (videoData.type === 'player:ready') {
                onReady();
            }
            else if (videoData.type === 'player:currentTime') {
                currentTime = videoData.data.time;
            }
            else if (videoData.type === 'player:durationChange') {
                duration = videoData.data.duration;
            }
            else if (videoData.type === 'player:playComplete') {
                window.removeEventListener('message', messageHandler);
            }
            else if (videoData.type === 'player:error') {
                window.removeEventListener('message', messageHandler);
            }
        }
    }
    window.addEventListener('message', messageHandler);
    return {
        playVideo: () => {
            // При нажатии на 'play' когда плеер находится в режиме воспроизведения выставляется пауза,
            // поэтому если и так воспроизводится то ничего не возвращаем
            if (lastEventName === 'player:currentTime')
                return;
            sendMessage({
                type: 'player:play',
                data: {}
            });
        },
        pauseVideo: () => {
            sendMessage({
                type: 'player:pause',
                data: {}
            });
        },
        mute: () => sendMessage({
            type: 'player:mute'
        }),
        unMute: () => sendMessage({
            type: 'player:unMute'
        }),
        seekTo: seconds => {
            sendMessage({
                type: 'player:setCurrentTime',
                data: {
                    time: seconds
                }
            });
        },
        setVolume: volume => sendMessage({
            type: 'player:setVolume',
            data: {
                volume: volume / 100
            }
        }),
        destroy: () => {
            frame.src = '';
        },
        on: (eventName, cb) => {
            if (eventName === 'player:ready') {
                onReady = cb;
            }
            else if (eventName === 'player:changeState') {
                onStateChange = cb;
            }
        },
        // возвращаем промисы, чтобы был одинаковый интерфейс с youtube-player
        getCurrentTime: () => __awaiter(this, void 0, void 0, function* () { return Promise.resolve(currentTime); }),
        getDuration: () => __awaiter(this, void 0, void 0, function* () { return Promise.resolve(duration); })
    };
}
exports.default = RutubePlayer;
