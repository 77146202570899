"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .pages {
        display: table-cell;
        height: 100%;
        vertical-align: middle;
    }

    .pages_isFeedback.pages_noFeedbackDemo {
        background-color: var(--page-bg);
    }
`;
