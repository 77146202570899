"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    :root {
        --text-color: #000;
        
        .pythia-surveys-root[data-theme='dark'] {
            --text-color: #FFF;
        }

        --main-font: 'YS Text Web', Arial, Helvetica, sans-serif;

        --radiobox-size-m-after-size: 6px;
        --radiobox-size-m-positionTop: 3px;
        --radiobox-size-m-size: 17px;

        /*// цвета дефолтного инпута фон*/
        --view-default-control-background: #fff;
        /*// цвет обрамления невыбранного инпута*/
        --view-default-control-color-border: rgba(0, 0, 0, 0.2);
        /*// обрамление невыбранного инпута*/
        --view-default-control-border: 3px solid var(--view-default-control-color-border);
        /*// цвет фона при инпуте выбранном*/
        --view-default-control-checked-background: #fc0;
        /*// цвет при выбраном контроле внутренного элемента*/
        --view-default-fill-color-internal: #000;

        --view-default-fill-color-action: #ffdb4d;
        --view-default-text-action-color: #000;

        --view-required-asterisk: red;
    }
    @font-face {
        font-family: 'YS Text Web';
        src: url('https://yastatic.net/islands/_/PyVcRbwHetz0gOVWLonWH7Od8zM.woff2') format('woff2'),
            url('https://yastatic.net/islands/_/bIx8jOfCEfR-mECoDUEZywDBuHA.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'YS Text Web';
        src: url('https://yastatic.net/islands/_/7_GKBdKFbUPzKlghJRv55xgz0FQ.woff2') format('woff2'),
            url('https://yastatic.net/islands/_/SmqPmIMEXrW4lOY8QrhTUVDbrro.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    .pythia-surveys-root {
        font-family: var(--main-font);
        font-display: swap;
        color: var(--text-color);

        font-size: 10px;
    }
    .app,
    .app__body {
        display: table;
        height: 100%;
        width: 100%;
    }
`;
