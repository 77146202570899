"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .app-error
    {
        padding: 5em;
        text-align: center;
        box-sizing: border-box;

        &__code
        {
            font-size: 10em;
        }

        &__message
        {
            font-size: 2em;
        }
    }
`;
