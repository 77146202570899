"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VH_ORIGIN = void 0;
const common_components_1 = require("@yandex-int/pythia-libs/lib/common-components");
exports.VH_ORIGIN = 'https://frontend.vh.yandex.ru';
const VhToYtEventMap = {
    ended: 0,
    error: 0,
    started: 1,
    resumed: 1,
    paused: 2,
    rewound: 2,
    inited: 3,
    timeupdate: 3
}; /*  as const */
// type EventNames = keyof typeof VhToYtEventMap;
// type VideoPlayer = any;
// простейший плеер для роликов видеохаба, апи приближенно к https://github.com/gajus/youtube-player#readme
function VhPlayer(frame) {
    let onReady = () => { };
    let onStateChange = () => { };
    let lastEventName;
    let currentTime = 0;
    let duration;
    // https://yandex.ru/support/video/partners/jsapi.html#control-player
    function sendMessage(data) {
        var _a;
        (_a = frame.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage(JSON.stringify(data), '*');
    }
    function checkStateChanged(eventName) {
        if (eventName !== 'timeupdate' &&
            (lastEventName === eventName || lastEventName === 'ended')) {
            return;
        }
        lastEventName = eventName;
        onStateChange({ data: VhToYtEventMap[eventName] });
    }
    /* eslint max-statements:0 */
    function messageHandler(event) {
        if (event.origin === exports.VH_ORIGIN) {
            const videoData = (0, common_components_1.parsePostMessageData)(event.data);
            checkStateChanged(videoData.event);
            if (videoData.event === 'inited') {
                onReady();
            }
            else if (videoData.event === 'started') {
                currentTime = videoData.time;
                duration = videoData.duration;
            }
            else if (videoData.event === 'paused') {
                currentTime = videoData.time;
            }
            else if (videoData.event === 'rewound') {
                currentTime = videoData.time;
            }
            else if (videoData.event === 'resumed') {
                currentTime = videoData.time;
                duration = videoData.duration;
            }
            else if (videoData.event === 'timeupdate') {
                currentTime = videoData.time;
                duration = videoData.duration;
            }
            else if (videoData.event === 'ended') {
                currentTime = videoData.time;
                window.removeEventListener('message', messageHandler);
            }
            else if (videoData.event === 'error') {
                currentTime = videoData.time;
                window.removeEventListener('message', messageHandler);
            }
        }
    }
    window.addEventListener('message', messageHandler);
    return {
        playVideo: () => sendMessage({ method: 'play' }),
        pauseVideo: () => sendMessage({ method: 'pause' }),
        mute: () => sendMessage({ method: 'mute' }),
        unMute: () => sendMessage({ method: 'unmute' }),
        seekTo: seconds => sendMessage({ method: 'seek', time: seconds }),
        setVolume: volume => sendMessage({ method: 'setVolume', volume: volume / 100 }),
        destroy: () => {
            frame.src = '';
        },
        on: (eventName, cb) => {
            if (eventName === 'ready') {
                onReady = cb;
            }
            else if (eventName === 'stateChange') {
                onStateChange = cb;
            }
        },
        // возвращаем промисы, чтобы был одинаковый интерфейс с youtube-player
        getCurrentTime: () => __awaiter(this, void 0, void 0, function* () { return Promise.resolve(currentTime); }),
        getDuration: () => __awaiter(this, void 0, void 0, function* () { return Promise.resolve(duration); })
    };
}
exports.default = VhPlayer;
