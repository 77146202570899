"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    font-size: 16px;
    line-height: 20px;
    color: rgba(47, 55, 71, 1);

    .question-table-scale__row {
        display: flex;
        margin-bottom: 10px;
    }

    .question-table-scale__row .question_hidden {
        background-color: transparent;
        padding: 0px;
        inset: 12px;
    }

    .question-table-scale__row .question_hidden:after {
        display: none;
    }

    .question-table-scale__row div.question.question_theme_common.question_sideimage_no {
        width: 100% !important;
    }

    .question-table-scale__columns-header {
        display: flex;
        margin-inline-start: 184px;
    }

    .question-table-scale__option-label {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .question-table-scale__no-option-label {
        width: 125px;
        min-width: 125px;
        word-break: break-word;
        text-align: center;
        margin-inline: 3px;
    }

    .question-table-scale__columns-header-wrapper {
        position: sticky;
        top: 0px;
        z-index: 100;
        padding: 18px 0px;
    }

    .question-table-scale__columns-header-wrapper {
        background: white;
    }

    .question-table-scale__columns-header-wrapper_isDemo {
        background: transparent;
        position: static;
    }

    @media (max-width: 640px) {
        .question-table-scale__columns-header-wrapper {
            display: none;
        }
    }
`;
