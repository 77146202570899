"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .page {
        --page-horizontal-padding: calc(var(--page-padding-left) + var(--page-padding-right));
        padding-top: var(--page-padding-top);
        padding-right: var(--page-padding-right);
        padding-left: var(--page-padding-left);
        padding-bottom: var(--page-padding-bottom);
        box-sizing: border-box;
        display: table;
        width: 100%;
        height: 100%;
        background: var(--page-bg);

        border-top: 0.2em solid rgba(0, 0, 0, 0.1);

        &__button {
            height: 3.8em;
            margin-top: 4em;
            text-align: center;
            display: table-row;
        }
    }
    @media (max-width: 550px) {
        .page {
            --page-horizontal-padding: calc(var(--page-padding-mobile-left) + var(--page-padding-mobile-right));
            padding-top: var(--page-padding-mobile-top);
            padding-right: var(--page-padding-mobile-right);
            padding-left: var(--page-padding-mobile-left);
            padding-bottom: var(--page-padding-mobile-bottom);
        }
    }

    /*
        PYTHIADEV-1334 больший отступ снизу, чтобы влазила кнопка
        переключения страниц на сафари, встроенном в телеграм
    */
    @supports (-webkit-overflow-scrolling: touch) {
        .page {
            padding-bottom: var(--page-safari-in-telegram-padding-bottom);
        }
    }

    .page__questions {
        display: table-cell;
        height: 100%;
        vertical-align: middle;
        position: relative;
    }

    .page__vendor-label {
        display: none;
    }

    .page__button-next {
        margin-top: var(--button-action-margin-top);
        position: relative;
        z-index: 100;
    }

    .page_theme_default {
        & .page__component + .page__component {
            margin-top: var(--page-gap);
        }
    }

    /* Стили transparent */
    .page_theme_transparent {
        background: rgba(255, 255, 255, 0);
        border: none;
        margin: 0 auto;
        padding: 1em 3em;
        text-align: center;
        height: 100%;
        display: table;

        max-width: 80%;

        .page__button {
            @media (min-height: 500px) {
                margin-bottom: 1em;
            }
        }

        .page__button-next {
            width: auto;
        }
    }

    .page_display-mode_video.page_wide_yes {
        max-width: 80%;
        margin-inline-start: auto;
        margin-inline-end: auto;
    }

    .page_display-mode_video.page_wide_no {
        margin-inline-start: auto;
        margin-inline-end: auto;

        @media (min-width: 400px) {
            max-width: 80%;
        }

        @media (min-width: 500px) {
            max-width: 75%;
        }

        @media (min-width: 600px) {
            max-width: 70%;
        }

        @media (min-width: 700px) {
            max-width: 65%;
        }
    }

    /* Стили ugc-cab */
    .page_theme_ugc-cab {
        padding: 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: stretch;

        &.page_first_yes {
            justify-content: center;

            .page__questions {
                margin: 0 24px;
            }

            .page__button {
                margin-top: 36px;
            }

            .page__button-next::before {
                background: #ffd600;
            }
        }

        .page__questions {
            height: unset;
        }

        .page__button {
            margin: 16px 24px;

            height: auto;
        }

        .page__button-next {
            margin: 0;

            width: 100%;
            height: auto;

            font-size: 16px;
            line-height: 60px;
            font-weight: bold;

            &::before {
                border: 0;
                border-radius: 6px;
            }
        }
    }
    .page_theme_ux {
        .page__questions {
            height: auto;
        }
        .question:not(.question_type_ux) {
            width: 86em;
            max-width: 860px;
            border: 0.2em solid rgba(0, 0, 0, 0.1);
            border-radius: 0.5em;
            margin: auto;
            padding: 3em;
            margin-top: 7em;
        }
        .page__button {
            margin-top: 2em;
            display: block;
        }
        .page__button-next {
            margin-top: var(--button-action-margin-top);
            margin-bottom: 3em;
        }

        @media (max-width: 550px) {
            .question:not(.question_type_ux) {
                width: 80vw;
            }
        }
    }

    /* Стили для встроенного опросника */
    .page {
        &.page_embedded_yes {
            margin: 0;
            border-radius: 0;
            height: inherit;
        }
    }
`;
