"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsx jsx */
const react_1 = require("@emotion/react");
const styles = __importStar(require("./video-status-bar.styles"));
function VideoStatusBar({ currentTime = 0, duration, clicks, titleTpl = '' }) {
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ css: styles.bar }, { children: [(0, jsx_runtime_1.jsx)("div", { css: styles.positionIndicator, style: { width: `${(currentTime / duration) * 100}%` } }), clicks.map(({ downTime, upTime, timestamp }) => {
                const barTitle = titleTpl
                    .replace('%1', String(Math.round(downTime)))
                    .replace('%2', String(Math.round(upTime)));
                return ((0, jsx_runtime_1.jsx)("div", { title: barTitle, css: styles.barItem, style: {
                        left: `${(downTime / duration) * 100}%`,
                        width: `${((upTime - downTime) / duration) * 100}%`
                    } }, `${timestamp}-${downTime}-${upTime}`));
            })] })));
}
exports.default = VideoStatusBar;
