"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .question-image-clickmap
    {
        width: 100%;
        text-align: center;
    }

    .question-image-clickmap__image-clickmap
    {
        position: relative;
        display: inline-block;
    }


    .question-image-clickmap__image
    {
        cursor: crosshair;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        display: block;
        max-width: 100%;
    }

    .question-image-clickmap__clickmap
    {
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .question-image-clickmap__click-point
    {
        position: absolute;
        background-color: #f00;
        width: 8px;
        height: 8px;
        transform: translate(-4px, -4px);
        border-radius: 50%;
    }
`;
