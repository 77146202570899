"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const root_1 = require("src/utils/root");
function useTextDir() {
    const dir = (0, react_redux_1.useSelector)((state) => {
        const { dir } = state.survey;
        return dir !== null && dir !== void 0 ? dir : 'ltr';
    });
    const root = (0, root_1.useRootService)().get();
    (0, react_1.useEffect)(() => {
        root.setAttribute('dir', dir);
    }, [dir, root]);
}
exports.default = useTextDir;
