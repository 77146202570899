"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .geo__suggest-container
    {
        position: relative;
        z-index: 101;
    }

    .geo__suggest-cover
    {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .geo__suggest
    {
        border: 1px solid rgba(15, 15, 15, .1);
        box-sizing: border-box;
        box-shadow: 0 4px 12px 0 rgba(165, 176, 186, 0.21), 0 20px 11px -14px rgba(165, 176, 186, 0.15);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: #fff;
        z-index: 10 ;
    }

    .geo__item
    {
        padding: 0.6em 1em;
        font-size: 1.3em;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .geo__item:hover
    {
        background: var(--view-default-fill-color-action);
    }
`;
