"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSpinnerProps = void 0;
const react_redux_1 = require("react-redux");
const selectors_1 = require("src/redux/sagas/selectors");
// eslint-disable-next-line import/prefer-default-export
function useSpinnerProps() {
    return (0, react_redux_1.useSelector)(state => {
        // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
        const { spinner: { color } = {} } = (0, selectors_1.getConfig)(state);
        return { color };
    });
}
exports.useSpinnerProps = useSpinnerProps;
