"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .container-with-timer {
        position: relative;
        min-height: 220px;
        margin: 2.5em 0;
        transition-property: margin;
        transition-duration: .5s;

        .widget-markup__text {
            display: flex;
            min-height: inherit;

            .markdown {
                flex: 1;
                align-self: center;
                text-align: center;
            }
        }
    }
`;
