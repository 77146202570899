"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .progress-bar {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 33%;
        transition: width 0.5s;
        z-index: 100;
    }

    .progress-bar_preset_default {
        height: 6px;
        background: var(--view-default-fill-color-action);
    }
`;
