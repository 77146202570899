"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function compileFontStyleString(branding) {
    const { mainFont = {} } = branding;
    const defaultFont = (mainFont === null || mainFont === void 0 ? void 0 : mainFont.defaultSelected)
        ? `'${mainFont.defaultSelected}'`
        : `'YS Text Web'`;
    const brandingFont = `${defaultFont}, Arial, Helvetica, sans-serif`;
    return `
        {
            ${brandingFont ? `--main-font: ${brandingFont};` : ''}
            ${(mainFont === null || mainFont === void 0 ? void 0 : mainFont.color) ? `--text-color: ${mainFont.color};` : ''}
        }
    `;
}
exports.default = compileFontStyleString;
