"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config = (state = {}, action) => {
    switch (action.type) {
        case 'SURVEY_LOADED_FROM_CONFIG': {
            const { questionOptions, groupChildren, pageOrder, redirects, revisionId, respReuse } = action.data;
            return {
                questionOptions,
                groupChildren,
                pageOrder,
                redirects,
                revisionId,
                respReuse
            };
        }
        default: {
            return state;
        }
    }
};
exports.default = config;
