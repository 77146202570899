"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config = (state = null, action) => {
    switch (action.type) {
        case 'ADD_NOTIFICATION': {
            const { message } = action;
            const newState = { message };
            return newState;
        }
        case 'REMOVE_NOTIFICATION': {
            return null;
        }
        default:
            return state;
    }
};
exports.default = config;
