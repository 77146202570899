"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileEmotionStyleString(branding) {
    const { emotion = {} } = branding;
    const { bg, block: { count2maxWidth, count3maxWidth, innerPosition, maxWidth, position } = {}, border: { radius, width: borderWidth, color: borderColor } = {}, focusVisible: { border: { color: focusVisibleBorderColor, } = {} } = {}, height, width = height, gap, selected: { scale: selectedScale, label: { color: selectedColor } = {} } = {}, hover: { scale: hoverScale, label: { color: hoverColor } = {} } = {}, label: { bg: labelBg, border: { radius: labelBorderRadius, width: labelBorderWidth, color: labelBorderColor } = {}, color, deviceType, fontSize, fontWeight, lineHeight, marginTop } = {}, mobile: { gap: mobileGap } = {} } = emotion;
    return `
        {
            ${count2maxWidth ? `--emotion-options-count-2-width: ${count2maxWidth};` : ''}
            ${count3maxWidth ? `--emotion-options-count-3-width: ${count3maxWidth};` : ''}
            ${maxWidth ? `--emotion-options-width: ${maxWidth};` : ''}
            ${position ? `--emotion-options-position: ${position};` : ''}
            ${bg ? `--emotion-bg: ${bg};` : ''}
            ${focusVisibleBorderColor ? `--emotion-focus-visible-border-color: ${focusVisibleBorderColor};` : ''}
            ${gap ? `--emotion-gap: ${gap};` : ''}
            ${mobileGap ? `--emotion-mobile-gap: ${mobileGap};` : ''}
            ${radius ? `--emotion-border-radius: ${radius};` : ''}
            ${borderWidth ? `--emotion-border-width: ${borderWidth};` : ''}
            ${borderColor ? `--emotion-border-color: ${borderColor};` : ''}
            ${labelBg ? `--emotion-label-bg: ${labelBg};` : ''}
            ${labelBorderRadius ? `--emotion-label-border-radius: ${labelBorderRadius};` : ''}
            ${labelBorderWidth ? `--emotion-label-border-width: ${labelBorderWidth};` : ''}
            ${labelBorderColor ? `--emotion-label-border-color: ${labelBorderColor};` : ''}
            ${color ? `--emotion-label-text-color: ${color};` : ''}
            ${selectedScale ? `--emotion-container-selected-scale: ${selectedScale};` : ''}
            ${selectedColor ? `--emotion-label-text-selected-color: ${selectedColor};` : ''}
            ${hoverScale ? `--emotion-container-hover-scale: ${hoverScale};` : ''}
            ${hoverColor ? `--emotion-label-text-hover-color: ${hoverColor};` : ''}
            ${height ? `--emotion-container-height: ${height};` : ''}
            ${fontSize ? `--emotion-label-font-size: ${fontSize};` : ''}
            ${fontWeight ? `--emotion-label-font-weight: ${fontWeight};` : ''}
            ${lineHeight ? `--emotion-label-line-height: ${lineHeight};` : ''}
            ${marginTop ? `--emotion-label-margin-top: ${marginTop};` : ''}
            ${deviceType ? `
                --emotion-label-display-desktop: ${(deviceType === 'all' || deviceType === 'desktop') ? 'block' : 'none'};
                --emotion-label-display-mobile: ${(deviceType === 'all' || deviceType === 'mobile') ? 'block' : 'none'};
            ` : ''}
            ${innerPosition ? `
                --emotion-justify-content: ${innerPosition};
                --emotion-container-max-width: ${innerPosition === 'space-between' ? 'none' : width || 'var(--emotion-container-height)'};
            ` : ''}
        }
    `;
}
exports.default = compileEmotionStyleString;
