"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsInternalSurvey = exports.useScope = void 0;
const react_redux_1 = require("react-redux");
const types_1 = require("src/types");
const INTERNAL_SCOPES = [types_1.SurveyScope.YandexNet, types_1.SurveyScope.YandexTeam];
function useScope() {
    return (0, react_redux_1.useSelector)((state) => {
        var _a;
        return (_a = state.survey.scope) !== null && _a !== void 0 ? _a : [];
    });
}
exports.useScope = useScope;
function useIsInternalSurvey() {
    const scope = useScope();
    return INTERNAL_SCOPES.some(item => scope.includes(item));
}
exports.useIsInternalSurvey = useIsInternalSurvey;
