"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetLang = exports.useSurveyLang = void 0;
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const root_1 = require("src/utils/root");
/** Возвращает текущий язык опроса */
function useSurveyLang() {
    return (0, react_redux_1.useSelector)((state) => {
        const { lang } = state.survey;
        return lang !== null && lang !== void 0 ? lang : 'ru';
    });
}
exports.useSurveyLang = useSurveyLang;
/**
 * Проставляет язык опроса на корневой узел опросника в dom,
 *
 * для корректной работы системных контролов браузера, переносов и тд
 */
function useSetLang() {
    const lang = useSurveyLang();
    const root = (0, root_1.useRootService)().get();
    (0, react_1.useEffect)(() => {
        root.setAttribute('lang', lang);
    }, [lang, root]);
}
exports.useSetLang = useSetLang;
