"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isClickMapVideoQuestionModel = exports.isUXQuestionModel = exports.isModelWithOtherAnswerWithTextOption = exports.isModelWithNoOpinionAnswerOption = exports.isModelWithNoAnswerOption = exports.isNumberQuestionModel = exports.isTextQuestionModel = exports.isTableQuestionModel = exports.isQuestionWithOptionsModel = exports.isChoiceQuestionModel = void 0;
const questions_1 = require("./questions");
function isChoiceQuestionModel(model) {
    return model.state.type === 'choice';
}
exports.isChoiceQuestionModel = isChoiceQuestionModel;
function isQuestionWithOptionsModel(model) {
    return (0, questions_1.isQuestionWithOptions)(model.state);
}
exports.isQuestionWithOptionsModel = isQuestionWithOptionsModel;
function isTableQuestionModel(model) {
    return (0, questions_1.isTableQuestion)(model.state);
}
exports.isTableQuestionModel = isTableQuestionModel;
function isTextQuestionModel(model) {
    return (0, questions_1.isTextQuestion)(model.state);
}
exports.isTextQuestionModel = isTextQuestionModel;
function isNumberQuestionModel(model) {
    return model.state.type === 'number';
}
exports.isNumberQuestionModel = isNumberQuestionModel;
function isModelWithNoAnswerOption(model) {
    return model.state.type === 'sidebyside' || model.state.type === 'choice';
}
exports.isModelWithNoAnswerOption = isModelWithNoAnswerOption;
function isModelWithNoOpinionAnswerOption(model) {
    return model.state.type === 'choice' ||
        model.state.type === 'emotion' ||
        model.state.type === 'rating' ||
        model.state.type === 'sidebyside' ||
        model.state.type === 'scale';
}
exports.isModelWithNoOpinionAnswerOption = isModelWithNoOpinionAnswerOption;
function isModelWithOtherAnswerWithTextOption(model) {
    return model.state.type === 'choice' ||
        model.state.type === 'emotion' ||
        model.state.type === 'rating' ||
        model.state.type === 'scale';
}
exports.isModelWithOtherAnswerWithTextOption = isModelWithOtherAnswerWithTextOption;
function isUXQuestionModel(model) {
    return model.state.type === 'ux';
}
exports.isUXQuestionModel = isUXQuestionModel;
function isClickMapVideoQuestionModel(model) {
    return model.state.type === 'clickmapVideo';
}
exports.isClickMapVideoQuestionModel = isClickMapVideoQuestionModel;
