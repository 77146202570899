"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.handlerClickNextPage = exports.handlerScreenout = exports.handlerMoveNextPage = exports.getRestVisibleQuestions = exports.sendFinish = exports.findNextPage = exports.getAnswers = void 0;
const effects_1 = require("redux-saga/effects");
const question_1 = require("src/utils/question");
const actions_1 = require("../actions");
const selectors_1 = require("./selectors");
function* getAnswers() {
    const answersFromQueryParams = yield (0, effects_1.select)(selectors_1.getQueryParamsAnswers);
    const answeredQuestions = new Set();
    const answers = [];
    if (answersFromQueryParams && answersFromQueryParams.length) {
        answers.push(...answersFromQueryParams);
        answersFromQueryParams.map(({ questionId }) => {
            answeredQuestions.add(questionId.toString());
        });
    }
    const questions = yield (0, effects_1.select)(selectors_1.getQuestions);
    const charLimits = yield (0, effects_1.select)(selectors_1.getCharLimits);
    Object.keys(questions).map(key => {
        const id = key.toString();
        if (answeredQuestions.has(id))
            return;
        const question = (0, question_1.createQuestionModel)(questions, Number(id), { charLimits });
        const values = question.getValueAsObj();
        answers.push(Object.assign(Object.assign({}, values), { questionId: id }));
    });
    return answers;
}
exports.getAnswers = getAnswers;
function* findNextPage() {
    const currPageIndex = yield (0, effects_1.select)(selectors_1.getCurrentPage);
    const pagesList = yield (0, effects_1.select)(selectors_1.getPages);
    const conditions = yield (0, effects_1.select)(selectors_1.getSurveyConditions);
    const nextPageIndex = yield (0, effects_1.call)(selectors_1.getNextPageIndex, { pagesList, currPageIndex, conditions });
    return nextPageIndex;
}
exports.findNextPage = findNextPage;
function* sendFinish() {
    const slug = yield (0, effects_1.select)(selectors_1.getSlug);
    const answers = yield (0, effects_1.call)(getAnswers);
    yield (0, effects_1.put)((0, actions_1.finishSurveyRequest)(slug, { answers }));
}
exports.sendFinish = sendFinish;
function getRestVisibleQuestions({ pages, questions, currPage }) {
    const restVisiblePages = pages.slice(currPage).filter(page => page.visible);
    const restVisibleQuestions = restVisiblePages.reduce((prev, next) => {
        return prev.concat(next.children.filter(({ id, type }) => type === 'question' && questions[id].visible));
    }, []);
    return restVisibleQuestions;
}
exports.getRestVisibleQuestions = getRestVisibleQuestions;
// eslint-disable-next-line max-statements, complexity
function* handlerMoveNextPage() {
    const currPage = yield (0, effects_1.select)(selectors_1.getCurrentPage);
    if (currPage === -1) {
        yield (0, effects_1.put)((0, actions_1.leftLastPage)());
    }
    const isFinished = yield (0, effects_1.select)(selectors_1.getFinishState);
    const isScreenout = yield (0, effects_1.select)(selectors_1.getScreenoutState);
    if (isFinished || isScreenout) {
        return;
    }
    if (currPage === -1) {
        return yield (0, effects_1.call)(sendFinish);
    }
    const pages = yield (0, effects_1.select)(selectors_1.getPages);
    const questions = yield (0, effects_1.select)(selectors_1.getQuestions);
    const restVisibleQuestions = yield (0, effects_1.call)(getRestVisibleQuestions, { pages, questions, currPage });
    if (!restVisibleQuestions.length) {
        return yield (0, effects_1.call)(sendFinish);
    }
}
exports.handlerMoveNextPage = handlerMoveNextPage;
function* handlerScreenout() {
    const currPageIndex = yield (0, effects_1.select)(selectors_1.getCurrentPage);
    yield (0, effects_1.put)((0, actions_1.moveNextPage)({ from: currPageIndex, to: -1 }));
}
exports.handlerScreenout = handlerScreenout;
// eslint-disable-next-line max-statements, complexity
function* handlerClickNextPage(params = {}) {
    const isLogicProcessing = yield (0, effects_1.select)(selectors_1.getLogicProcessing);
    if (isLogicProcessing)
        return;
    // @ts-expect-error TS(2339): Property 'skipValidation' does not exist on type '... Remove this comment to see the full error message
    const { skipValidation = false } = params;
    const invalidQuestions = yield (0, effects_1.select)(selectors_1.getInvalidQuestionsOnCurrentPage);
    if (!skipValidation && invalidQuestions.length) {
        yield (0, effects_1.put)((0, actions_1.showQuestionsErrors)(invalidQuestions));
        yield (0, effects_1.put)((0, actions_1.scrollToQuestion)(invalidQuestions[0]));
        return;
    }
    const conditions = yield (0, effects_1.select)(selectors_1.getSurveyConditions);
    if (conditions.skip) {
        return yield (0, effects_1.put)((0, actions_1.surveySkip)());
    }
    const currPageIndex = yield (0, effects_1.select)(selectors_1.getCurrentPage);
    const currPageId = yield (0, effects_1.select)(selectors_1.getPageId, currPageIndex);
    const screenout = yield (0, effects_1.select)(selectors_1.getScreenout, currPageId);
    const isScreenout = screenout && !screenout.valid;
    const newPageIndex = yield (0, effects_1.call)(findNextPage);
    // Если это переход не на страницу "Спасибо", то логируем его
    if (newPageIndex !== -1 && !isScreenout) {
        const slug = yield (0, effects_1.select)(selectors_1.getSlug);
        const newPageId = yield (0, effects_1.select)(selectors_1.getPageId, newPageIndex);
        yield (0, effects_1.put)((0, actions_1.storePageSwitch)(slug, currPageId, newPageId));
    }
    yield (0, effects_1.put)((0, actions_1.sendAnswersToMetrika)());
    if (isScreenout) {
        const optionIds = yield (0, effects_1.select)(selectors_1.getCurrentPageSelectedOptions);
        const slug = yield (0, effects_1.select)(selectors_1.getSlug);
        const { search } = yield (0, effects_1.select)(selectors_1.getLocation);
        yield (0, effects_1.put)((0, actions_1.leftLastPage)());
        yield (0, effects_1.put)((0, actions_1.sendScreenout)({
            slug,
            pageFrom: currPageIndex,
            optionIds,
            queryString: search.substring(1)
        }));
        return;
    }
    yield (0, effects_1.put)((0, actions_1.moveNextPage)({ from: currPageIndex, to: newPageIndex }));
}
exports.handlerClickNextPage = handlerClickNextPage;
function* router() {
    yield (0, effects_1.takeEvery)('CLICK_NEXT_PAGE', handlerClickNextPage);
    yield (0, effects_1.takeEvery)('MOVE_NEXT_PAGE', handlerMoveNextPage);
    yield (0, effects_1.takeEvery)('SURVEY_SCREENOUT_SUCCESS', handlerScreenout);
}
exports.router = router;
