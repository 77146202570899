"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const themeMode = (state = 'system', action) => {
    switch (action.type) {
        case 'SET_THEME_MODE': {
            const { mode } = action;
            return mode;
        }
        default: {
            return state;
        }
    }
};
exports.default = themeMode;
