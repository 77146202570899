"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    &.notifications {
        position: absolute;
        top: 100px;
        text-align: center;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;
        z-index: 1000;
    }

    .notifications__wrapper {
        display: inline-flex;
        padding: 15px 20px;
        font-size: 16px;
        line-height: 24px;
        background-color: rgba(27, 32, 43, 1);
        border-radius: 14px;
        justify-content: center;
        align-items: center;
        color: white;
        max-width: 70%;
    }
    .notifications__message-icon {
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDEwQzIwIDE1LjUyMjggMTUuNTIyOCAyMCAxMCAyMEM0LjQ3NzE1IDIwIDAgMTUuNTIyOCAwIDEwQzAgNC40NzcxNSA0LjQ3NzE1IDAgMTAgMEMxNS41MjI4IDAgMjAgNC40NzcxNSAyMCAxMFpNMTUuMTY0NCA1Ljc1MjU5QzE0Ljc1MTYgNS4zODU2NyAxNC4xMTk1IDUuNDIyODUgMTMuNzUyNiA1LjgzNTY0TDguNDg2MDYgMTEuNzYwNUw2LjIzNDg1IDkuMzIxMzRDNS44NjAyNyA4LjkxNTUgNS4yMjc2MSA4Ljg5MDE1IDQuODIxNzcgOS4yNjQ3M0M0LjQxNTkyIDkuNjM5MzEgNC4zOTA1NyAxMC4yNzIgNC43NjUxNSAxMC42Nzc4TDcuMzkwNzYgMTMuNTIyNkM3Ljk1MjYzIDE0LjEzMTQgOC45MDE2MSAxNC4xNjk0IDkuNTEwMzggMTMuNjA3NUM5LjUzNzE5IDEzLjU4MTkgOS41NTA2IDEzLjU2OTEgOS41NjM1NyAxMy41NTU5QzkuNTc2NTQgMTMuNTQyNyA5LjU4OTA4IDEzLjUyOTEgOS42MTQxNSAxMy41MDE4TDE1LjI0NzQgNy4xNjQzNkMxNS42MTQzIDYuNzUxNTggMTUuNTc3MSA2LjExOTUxIDE1LjE2NDQgNS43NTI1OVoiIGZpbGw9IiM3NkQ5ODMiLz4KPC9zdmc+Cg==');
    }

    .notifications__message-text {
        margin-left: 10px;
    }
`;
