"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (tumblerWidth) => (0, react_1.css) `
    .header {
        height: 140px;
        scroll-behavior: smooth;
    }
    .header__navigation {
        position: fixed;
        z-index: 101;
        top: 0;
        width: 100%;
    }
    .header__wrapper {
        background-color: white;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding-bottom: 15px;
        position: relative;
        top: -20px;
        border-bottom: 0.2em solid rgba(0, 0, 0, 0.1);
    }
    [dir='rtl'] .header__wrapper {
        flex-direction: row-reverse;
    }

    .pythia-surveys-root[data-theme="dark"] .header__wrapper {
        color: #2f3747;
    }

    .header__tumbler {
        padding-right: 20px;
        position: relative;
        top: 30px;
    }

    .header__label-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        margin-right: ${tumblerWidth}px;
        max-width: calc(100% - ${2 * tumblerWidth}px);
        position: relative;
        top: 30px;
    }

    .header__label-container > .header__icon-left {
        margin-right: 10px;
    }

    .header__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 36px;
        height: 36px;
        background-color: rgba(176, 189, 214, 0.2);
        border-radius: 12px;
    }

    .header__icon:hover {
        cursor: pointer;
        background-color: rgba(176, 189, 214, 0.4);
    }

    .header__scrollpanel-wrapper {
        overflow: hidden;
        box-sizing: content-box;
    }

    .header__scrollpanel {
        display: flex;
        flex: 1 0 auto;
        overflow-x: auto;
        padding-bottom: 30px;
        box-sizing: content-box;
        height: 100%;
        width: 100%;
        padding-top: 20px;
        position: relative;
        top: 5px;
    }
    .header__label {
        all: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 48px;
        height: 36px;
        border: 1px solid rgba(165, 177, 202, 0.3);
        border-radius: 12px;
        font-size: 16px;
    }

    .header__label:hover {
        cursor: pointer;
    }

    .header__label-wrapper {
        padding-right: 4px;
        padding-left: 4px;
    }

    .header__label_isCurrent {
        background-color: rgba(176, 189, 214, 0.2);
    }
    .header__spacer {
        padding-top: 140px;
    }

    .header__mobile-wrapper {
        display: none;
    }

    .header__rotation-group {
        display: inline-flex;
        border: 1.5px solid var(--group-color);
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        margin-right: 4px;
        position: relative;
        padding: 4px 0px;
    }

    .header__rotation-group-label {
        position: absolute;
        top: -25px;
        left: 0;
        color: rgba(26, 43, 77, 0.6);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    @media only screen and (min-width: 640px) and (max-width: 800px) {
        .header__wrapper {
            align-items: center;
            justify-content: space-between;
        }
        .header__label-container {
            margin-right: 0px;
            max-width: 50%;
        }
    }

    @media (max-width: 640px) {
        .header {
            height: auto;
        }
        .header__wrapper {
            display: none;
        }
        .header__mobile-wrapper {
            display: block;
            position: relative;
            padding: 8px 16px;
        }
        .tumbler__label-text {
            display: none;
        }

        .header__navigation {
            background-color: #2f3747;
            border: none;
        }

        .header__image-indicator {
            margin-right: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .header__mobile-btn {
            unset: all;
            padding: 8px 12px;
            background: white;
            border: none;
            border-radius: 10px;
            color: rgba(47, 55, 71, 1);
            font-size: 14px;
        }

        .header__mobile-btn:active {
            background: #e0e0e0;
        }

        .header__mobile-btn:hover {
            cursor: pointer;
        }

        .header__tumbler {
            position: revert;
        }
        .header__tumbler-wrapper {
            display: inline-flex;
            justify-content: space-between;
            position: absolute;
            top: 16px;
            right: calc(50% - 35px);
        }

        .header__dropdown {
            background: white;
            margin-top: 8px;
            padding: 10px 0;
            border-radius: 14px;
            font-size: 16px;
            line-height: 24px;
        }

        .header__dropdown-option {
            all: unset;
            padding: 10px 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 40px);
        }

        .header__dropdown-option:hover {
            cursor: pointer;
        }

        .header__rotation-marker {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: var(--group-color);
        }
        .header__dropdown-option_current {
            background: rgba(176, 189, 214, 0.2);
        }

        .header__dropdown-list {
            max-height: 70vh;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
`;
