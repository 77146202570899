"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.positionIndicator = exports.barItem = exports.bar = void 0;
const react_1 = require("@emotion/react");
exports.bar = (0, react_1.css) `
    height: 5px;

    position: relative;
    overflow: hidden;

    box-shadow: 0 0 0 1px inset rgb(0 0 0 / 7%);
    box-sizing: border-box;
`;
exports.barItem = (0, react_1.css) `
    position: absolute;
    height: 100%;
    background: #00b341;
    width: 50px;

    &:hover {
        background: #00bc45;
    }
`;
exports.positionIndicator = (0, react_1.css) `
    position: absolute;
    height: 100%;
    background: rgba(0, 0, 0, 0.07);
`;
