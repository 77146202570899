"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    :root {
        --page-height: 100%;
    }

    .app {
        background: var(--feedback-demo-bg);
    }

    .app_theme_default.app_surveyPreset_yandexActiveFeedback .page,
    .app_theme_default.app_surveyPreset_yandexMorda .page {
        width: min(320px, 100%);
        background: var(--page-bg);
    }

    .app_surveyPreset_yandexPassiveFeedback .page {
        width: min(545px, 100%);
        background: var(--page-bg);
    }

    .footer {
        background: var(--feedback-demo-bg);
    }
`;
