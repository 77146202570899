"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .root_scroll-bar_disabled {
        scrollbar-width: none;
    }
    .root_scroll-bar_disabled::-webkit-scrollbar {
        display: none;
    }
`;
