"use strict";
/* eslint-disable import/prefer-default-export */
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendError = void 0;
const sendError = (error, { source, type, additional } = {}) => {
    window.Ya.Rum.logError({
        message: (error && error.message) || 'Unexpected error',
        level: 'error',
        type: type || 'logic',
        source: source || 'frontend-survey',
        additional
    }, error);
};
exports.sendError = sendError;
