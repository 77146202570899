"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .app_surveyPreset_yandexMorda .scale__option-label {
        font-size: 18px;
    }

    .app_surveyPreset_yandexMorda .scale__options.scale__options_manyOptions {
        margin-bottom: var(--scale-mobile-many-options-margin-bottom);
    }

    .app_surveyPreset_yandexMorda .scale__options.scale__options_manyOptions .scale__option-label {
        width: 23px;
        line-height: 23px;
        font-size: 14px;
    }
    .app_surveyPreset_yandexActiveFeedback .scale__options.scale__options_manyOptions .scale__option-label {
        width: 1.5em;
        line-height: 1.5em;
        font-size: 1.02em;
    }
    @media (max-width: 400px) {
        .app_surveyPreset_yandexActiveFeedback .scale__options.scale__options_manyOptions .scale__option-label {
            font-size: 14px;
        }
    }
`;
