"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .paginator {
        @media (max-height: 300px), (max-width: 300px) {
            display: none;
        }

        width: 100%;
        text-align: center;
        height: 1.5em;
        font-size: 1.3em;
        padding: 1em 0;

        &__container {
            overflow: hidden;
            height: 1.5em;
            text-align: center;
            padding-inline-end: 1px; /* увеличиваем ширину на 1px, чтобы не обрезалось из-за округления вниз дробной ширины */
            margin: auto;
            display: inline-block;
        }

        &__pages {
            display: table;
            white-space: nowrap;
            transition: transform 0.5s;
        }

        &__page {
            display: inline-block;
            transition: opacity 0.2s 0.2s;
            width: 1.5em;
            height: 1.5em;
            position: relative;

            &_active_yes {
                opacity: 1;

                & .paginator__page-text {
                    opacity: 1;
                }
            }

            &_active_no {
                opacity: 0.4;
            }
        }
        &__page + &__page {
            margin-inline-start: 1.5em;
        }

        &__page-circle {
            display: inline-block;
            width: 1.5em;
            height: 1.45em;
            line-height: 1.5em;
            background: #fff;
            border-radius: 50%;
            text-align: center;
        }

        &__page-text {
            color: #000;
            opacity: 0;
            transition: opacity 0.2s 0.2s;
        }
    }
`;
