"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeLimitType = exports.AttachmentStatus = void 0;
exports.AttachmentStatus = {
    Initial: 0,
    Playing: 1,
    End: 2,
    0: 'Initial',
    1: 'Playing',
    2: 'End'
};
exports.TimeLimitType = {
    NOT_MORE: 0,
    NOT_LESS: 1,
    WAIT_VIDEO_END: 2,
    0: 'NOT_MORE',
    1: 'NOT_LESS',
    2: 'WAIT_VIDEO_END'
};
