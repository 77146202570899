"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsMobile = exports.useResizeObserver = void 0;
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const actions_1 = require("src/redux/actions");
const selectors_1 = require("src/redux/sagas/selectors");
const MOBILE_MAX_WIDTH = 640;
// eslint-disable-next-line import/prefer-default-export
function useResizeObserver(ref) {
    const dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(() => {
        const resizeObserver = new ResizeObserver(() => {
            dispatch((0, actions_1.resize)());
        });
        resizeObserver.observe(ref.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, [dispatch, ref]);
}
exports.useResizeObserver = useResizeObserver;
function useIsMobile() {
    return (0, react_redux_1.useSelector)((state) => {
        const feedbackDemoMode = (0, selectors_1.getIsFeedbackDemoMode)(state);
        return state.dims.width <= MOBILE_MAX_WIDTH || feedbackDemoMode;
    });
}
exports.useIsMobile = useIsMobile;
