"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
function getStyles() {
    return (0, react_1.css) `
        .widget-markup {
            margin: 0;
            opacity: 1;
            transition: height 0.3s ease-out, opacity 0.3s ease-out;
            height: auto;

            &__label {
                text-align: start;
            }
        }

        .widget-markup .markdown p {
            font-size: var(--widget-font-size);
            line-height: var(--widget-line-height);
            font-weight: var(--widget-font-weight);
        }

        &.widget-markup_hidden {
            background-color: rgba(246, 248, 251, 1);
            padding: 16px 12px;
            border-radius: 12px;
            position: relative;
            margin-inline: -12px;
            width: 100%;
        }

        .widget-markup__bubble {
            margin-bottom: 12px;
            margin-right: 12px;
            display: inline-block;
            padding: 3px 6px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            color: rgba(26, 43, 77, 0.6);
            border-radius: 8px;
            border: 1px solid rgba(255, 221, 87, 0.8);
            background-color: rgba(246, 248, 251, 1);
        }

        .phone .widget-markup {
            width: 100%;
            margin: 0.4em 0;
            padding: 1em 0;
        }
    `;
}
exports.default = getStyles;
