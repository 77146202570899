"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    &.special-options-as-buttons {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: var(--special-buttons-margin-top);
    }

    .special-options-as-buttons__buttons {
        max-width: 100%;
        min-width: var(--special-buttons-min-width);
    }

    &.special-options-as-buttons button,
    .special-options-as-buttons__other-answer-wrap {
        width: 100%;
        display: block;
        min-height: var(--special-buttons-min-height);
        margin: var(--special-button-margin);
    }

    .special-options-as-buttons__other-answer-wrap {
        margin-top: var(--special-buttons-other-answer-input-margin-top);
        position: relative;
    }
`;
