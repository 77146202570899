"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileTooltipStyleString(branding) {
    const { tooltip = {} } = branding;
    const { bg, border: { color: borderColor, radius, width, } = {}, color, fontSize, fontWeight, lineHeight, padding, shadow, top } = tooltip;
    return `
        {
            ${bg ? `--tooltip-bg: ${bg};` : ''}
            ${borderColor ? `--tooltip-border-color: ${borderColor};` : ''}
            ${radius ? `--tooltip-border-radius: ${radius};` : ''}
            ${width ? `--tooltip-border-width: ${width};` : ''}
            ${color ? `--tooltip-text-color: ${color};` : ''}
            ${fontSize ? `--tooltip-font-size: ${fontSize};` : ''}
            ${fontWeight ? `--tooltip-font-weight: ${fontWeight};` : ''}
            ${lineHeight ? `--tooltip-line-height: ${lineHeight};` : ''}
            ${padding ? `--tooltip-padding: ${padding};` : ''}
            ${shadow ? `--tooltip-shadow: ${shadow};` : ''}
            ${top ? `--tooltip-top: ${top};` : ''}
        }
    `;
}
exports.default = compileTooltipStyleString;
