"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCROLL_CHECK_ACTIONS = exports.QUESTION_INTERACTION_ACTIONS = exports.QUESTION_CHANGE_ACTIONS = exports.SURVEY_SUCCESSFUL_LOAD_ACTIONS = exports.QUESTION_ANSWER_ACTIONS = void 0;
exports.QUESTION_ANSWER_ACTIONS = [
    'QUESTION_CLICK_NO_ANSWER',
    'QUESTION_CLICK_NO_OPINION_ANSWER',
    'QUESTION_CLICK_OPTION',
    'QUESTION_CLICK_OTHER',
    'QUESTION_CLICK_OTHER_WITH_TEXT',
    'QUESTION_CLICKMAP_IMAGE_CLICK',
    'QUESTION_VIDEO_END'
];
exports.SURVEY_SUCCESSFUL_LOAD_ACTIONS = [
    'SURVEY_SET_INITED',
    'SURVEY_RESTORED_FROM_PRELOADED_STATE'
];
exports.QUESTION_CHANGE_ACTIONS = ['QUESTION_BLUR_TEXT'].concat(exports.QUESTION_ANSWER_ACTIONS);
exports.QUESTION_INTERACTION_ACTIONS = exports.QUESTION_CHANGE_ACTIONS.concat([
    'QUESTION_CHANGE_OTHER_WITH_TEXT',
    'QUESTION_CHANGE_TEXT'
]);
// события на которые надо проверять наличие скролла
exports.SCROLL_CHECK_ACTIONS = [
    'MOVE_NEXT_PAGE',
    'QUESTIONS_SHOW_ERRORS',
    'SURVEY_LOADED_FROM_CONFIG',
    'SURVEY_SET_INITED',
    'SURVEY_RESTORED_FROM_PRELOADED_STATE'
].concat(exports.QUESTION_ANSWER_ACTIONS);
