"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .pythia-surveys-root[data-theme='dark'] {
        .question-text__postfix {
            color: var(--input-control-color);
        }
    }
        
    .question-text__postfix {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 1em;

        line-height: 2.4;
        color: rgba(26, 43, 77, 0.6);
        font-family: var(--main-font);
        font-size: 1.5em;
    }

    .question-text__input-wrap {
        position: relative;
    }
`;
