"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function compileFinalPageStyleString(branding) {
    const { finalPage = {} } = branding;
    const { fontWeight, fontSize, lineHeight, padding } = finalPage;
    return `
        {
            ${Number.isInteger(fontWeight) ? `--page-final-font-weight: ${fontWeight};` : ''}
            ${fontSize ? `--page-final-font-size: ${fontSize};` : ''}
            ${lineHeight ? `--page-final-line-height: ${lineHeight};` : ''}
            ${padding ? `--page-final-padding: ${padding};` : ''}
        }
    `;
}
exports.default = compileFinalPageStyleString;
