"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
function isLocalStorageAvailable() {
    const x = '__storage_test__';
    try {
        localStorage.setItem(x, x);
        localStorage.removeItem(x);
        return true;
    }
    catch (_) {
        return false;
    }
}
class Client {
    constructor(prefix) {
        if (!prefix) {
            throw new Error('Did not pass prefix');
        }
        this.isAvailable = isLocalStorageAvailable();
        this.prefix = prefix;
    }
    set(id, data) {
        if (!this.isAvailable) {
            console.error('localStorage is not available');
            return;
        }
        if (typeof data !== 'string') {
            data = JSON.stringify(data);
        }
        id = `${this.prefix}-${id}`;
        try {
            localStorage.setItem(id, data);
        }
        catch (e) {
            console.error(e);
        }
    }
    get(id) {
        if (!this.isAvailable) {
            console.error('localStorage is not available');
            return null;
        }
        id = `${this.prefix}-${id}`;
        try {
            return JSON.parse(localStorage.getItem(id));
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }
    has(id) {
        if (!this.isAvailable) {
            console.error('localStorage is not available');
            return false;
        }
        id = `${this.prefix}-${id}`;
        try {
            return Boolean(JSON.parse(localStorage.getItem(id)));
        }
        catch (e) {
            console.error(e);
            return false;
        }
    }
    del(id) {
        if (!this.isAvailable) {
            console.error('localStorage is not available');
            return;
        }
        id = `${this.prefix}-${id}`;
        try {
            localStorage.removeItem(id);
        }
        catch (e) {
            console.error(e);
        }
    }
    static canUse() {
        return isLocalStorageAvailable();
    }
}
exports.default = Client;
