"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .curtain {
        top: -10px;
        bottom: -10px;
        left: -10px;
        right: -10px;
        position: absolute;
        background: rgba(47, 47, 47, 0.4);
        backdrop-filter: blur(50px);
        border-radius: 32px;
        pointer-events: none;
        text-align: center;
        line-height: 24px;
        font-size: 16px;
        display: none;

        .light {
            background: rgba(221, 221, 221, 0.2);
        }

        &__button {
            background: #fff;
            font-size: 16px;
            line-height: 24px;
            display: inline-block;
            padding: 10px 20px;
            border-radius: 14px;
        }

        &__wrap {
            color: #fff;
            height: 55%;
            margin: 0 25px;
            display: flex;
            min-height: 120px;

            p {
                align-self: flex-end;
            }
        }
    }

    .curtain_enabled {
        display: block;
        cursor: pointer;
    }

    .curtain_clickable,
    .curtain_ended {
        pointer-events: auto;
    }

    @supports (-moz-appearance:none) {
        .curtain {
            background: rgba(147, 147, 147, 1);
        }
    }

    @supports not (backdrop-filter: blur(50px)) {
        .curtain {
            background: rgba(147, 147, 147, 1);
        }
    }
`;
