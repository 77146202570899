"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileBgStyleString(bg = {}) {
    const { bgCustom, bgImg, bgSelected = 'color', colors } = bg;
    let appBg = '';
    switch (bgSelected) {
        case 'color':
            if (colors === null || colors === void 0 ? void 0 : colors.bg)
                appBg = colors.bg;
            break;
        case 'image':
            if (bgImg === null || bgImg === void 0 ? void 0 : bgImg.src) {
                const { src, repeat = 'no-repeat', size = 'cover', position = 'center' } = bgImg;
                appBg = `url(${src}) ${repeat} ${position}/${size}`;
            }
            break;
        case 'custom':
            if (bgCustom)
                appBg = bgCustom;
            break;
        default:
            break;
    }
    if (!appBg)
        return '';
    return `{
        --app-bg: ${appBg};
    }`;
}
exports.default = compileBgStyleString;
