"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.checkIdle = exports.checkIdleTask = void 0;
const effects_1 = require("redux-saga/effects");
const redux_saga_1 = require("redux-saga");
const selectors_1 = require("./selectors");
const consts_1 = require("./consts");
const transition_1 = require("./transition");
function* checkIdleTask() {
    const pages = yield (0, effects_1.select)(selectors_1.getPages);
    const currPageIndex = yield (0, effects_1.select)(selectors_1.getCurrentPage);
    const pageData = pages[currPageIndex];
    const skipTimeout = pageData ? parseInt(pageData.skipTime, 10) : 0;
    if (skipTimeout) {
        yield (0, effects_1.call)(redux_saga_1.delay, skipTimeout);
        yield (0, effects_1.call)(transition_1.handlerClickNextPage, { skipValidation: true });
    }
}
exports.checkIdleTask = checkIdleTask;
function* checkIdle() {
    // запускаем таску с таймером на неактивность
    const сheckTask = yield (0, effects_1.fork)(checkIdleTask);
    // если отвечали на вопрос/взаимодействовали/тыкнули на кнопку далее, идем дальше
    yield (0, effects_1.take)([...consts_1.QUESTION_INTERACTION_ACTIONS, 'CLICK_NEXT_PAGE']);
    // отменяем таску с таймером
    yield (0, effects_1.cancel)(сheckTask);
}
exports.checkIdle = checkIdle;
function* router() {
    yield (0, effects_1.takeLatest)(['SURVEY_SET_INITED', 'SURVEY_LOADED_FROM_CONFIG', 'PAGE_SWITCHED'], checkIdle);
}
exports.router = router;
