"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const conditions = (state = {}, action) => {
    switch (action.type) {
        case 'SURVEY_GET_SUCCESS':
        case 'SURVEY_LOADED_FROM_CONFIG': {
            return action.data.conditions || {};
        }
        default:
            return state;
    }
};
exports.default = conditions;
