"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .footer {
        padding: 3em 2.5em;
        display: flex;
        justify-content: space-between;
    }

    .footer__legal {
        color: rgba(71, 90, 128, 0.5);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        max-width: 40%;
        overflow-wrap: anywhere;
    }

    .footer__links {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer__link + .footer__link {
        margin-inline-start: 24px;
    }

    .footer__link {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        color: rgba(71, 90, 128, 0.5);
    }
    
    .footer__link img {
        display: inline-block;
        vertical-align: middle;
        height: 1em;
        width: auto;
        margin: 0 0.2em;
    }

    @media (max-width: 550px) {
        border-top: 0.2em solid rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 1200px) {
        .footer {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
        }

        .footer__links {
            flex-direction: column;
        }

        .footer__link + .footer__link {
            margin-inline-start: 0;
            margin-top: 8px;
        }

        .footer__legal {
            margin-top: 8px;
            max-width: 100%;
        }
    }

    @media (max-width: 549px) {
        .app_withHeader .footer {
            background-color: rgb(239, 242, 247);
        }
    }
`;
