"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.checkActiveRevisionId = exports.requestActiveRevisionId = void 0;
const effects_1 = require("redux-saga/effects");
const actions_1 = require("../actions");
const selectors_1 = require("./selectors");
function* requestActiveRevisionId() {
    const slug = yield (0, effects_1.select)(selectors_1.getSlug);
    yield (0, effects_1.put)((0, actions_1.getActiveRevisionId)(slug));
}
exports.requestActiveRevisionId = requestActiveRevisionId;
function* checkActiveRevisionId(action) {
    const { data: { revisionId } } = action;
    const currentRevisionId = yield (0, effects_1.select)(selectors_1.getRevisionId);
    if (revisionId > currentRevisionId) {
        const slug = yield (0, effects_1.select)(selectors_1.getSlug);
        const { search } = yield (0, effects_1.select)(selectors_1.getLocation);
        const queryString = search.substring(1);
        yield (0, effects_1.put)((0, actions_1.getSurveyRequest)(slug, queryString));
    }
}
exports.checkActiveRevisionId = checkActiveRevisionId;
function* router() {
    yield (0, effects_1.takeEvery)('SURVEY_RESTORED_FROM_PRELOADED_STATE', requestActiveRevisionId);
    yield (0, effects_1.takeEvery)('REVISION_ID_GET_SUCCESS', checkActiveRevisionId);
}
exports.router = router;
