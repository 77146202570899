"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInIframe = exports.EVENTS = void 0;
exports.EVENTS = {
    RENDERED: 'survey.embed.rendered',
    REJECTED: 'survey.embed.rejected',
    FINISHED: 'survey.embed.finished',
    SCREENOUT: 'survey.embed.screenout',
    FIRST_ANSWER: 'survey.embed.first_answer_given',
    PAGE_SWITCHED: 'survey.embed.page_switched',
    REDIRECTED: 'survey.embed.redirected',
    LEFT_LAST_PAGE: 'survey.embed.left_last_page',
    TEXT_RESIZED_TO_MIN: 'survey.embed.text_resized_to_min',
    DISPLAY_HIDDEN_QUESTIONS: 'survey.embed.display_hidden_questions',
    SET_THEME_MODE: 'survey.embed.set_theme_mode',
    HEIGHT_CHANGED: 'survey.embed.height_changed'
};
function isInIframe() {
    try {
        return window.self !== window.top;
    }
    catch (_) {
        return true;
    }
}
exports.isInIframe = isInIframe;
