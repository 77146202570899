"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.setDisplayHiddenQuestions = void 0;
const effects_1 = require("redux-saga/effects");
const actions_1 = require("../actions");
const selectors_1 = require("./selectors");
function* setDisplayHiddenQuestions() {
    var _a;
    const config = yield (0, effects_1.select)(selectors_1.getConfig);
    const { targetPageIndex, pageIsVisible } = yield (0, effects_1.select)(selectors_1.getPageSelected);
    const shouldDisplayHidden = Boolean((_a = config === null || config === void 0 ? void 0 : config.hidden) === null || _a === void 0 ? void 0 : _a.enable);
    yield (0, effects_1.put)((0, actions_1.setHiddenQuestionsDisplay)(shouldDisplayHidden));
    if (targetPageIndex) {
        if (!pageIsVisible) {
            yield (0, effects_1.put)((0, actions_1.setHiddenQuestionsDisplay)(true));
        }
        yield (0, effects_1.put)((0, actions_1.setCurrentPage)(targetPageIndex));
    }
}
exports.setDisplayHiddenQuestions = setDisplayHiddenQuestions;
function* toggleDislayHiddenQuestions() {
    const displayHiddenQuestions = yield (0, effects_1.select)(selectors_1.getDisplayHiddenQuestions);
    yield (0, effects_1.put)((0, actions_1.setHiddenQuestionsDisplay)(!displayHiddenQuestions));
}
function* router() {
    yield (0, effects_1.takeEvery)('SURVEY_GET_SUCCESS', setDisplayHiddenQuestions);
    yield (0, effects_1.takeEvery)('NODE_VISIBILITY_CHANGED', setDisplayHiddenQuestions);
    yield (0, effects_1.takeEvery)('SURVEY_GET_FAILURE', setDisplayHiddenQuestions);
    yield (0, effects_1.takeEvery)('SURVEY_RESTORED_FROM_PRELOADED_STATE', setDisplayHiddenQuestions);
    yield (0, effects_1.takeEvery)('SURVEY_LOADED_FROM_CONFIG', setDisplayHiddenQuestions);
    yield (0, effects_1.takeEvery)('TOGGLE_HIDDEN_QUESTIONS_DISPLAY', toggleDislayHiddenQuestions);
}
exports.router = router;
