"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MORDA_SKIN = void 0;
// в двойных кавычках, чтобы было удобнее копировать как JSON
// eslint-disable-next-line import/prefer-default-export
exports.MORDA_SKIN = {
    "button": {
        "bgColor": "rgba(255, 204, 0, 1)",
        "focusVisible": {
            "border": {
                "color": "#0060D0"
            }
        },
        "fontSize": "13px",
        "fontWeight": "500",
        "minWidth": "100%",
        "height": "36px",
        "borderRadius": "10px",
        "disabled": {
            "bgColor": "rgba(0, 0, 0, 0.12)",
            "textOpacity": 1
        },
        "margin": {
            "top": "2em"
        }
    },
    "colors": {
        "bg": "#fff"
    },
    "choice": {
        "option": {
            "fontSize": "1.5em",
            "fontWeight": "400",
            "marginInlineEnd": "0",
            "marginTop": "1.2em",
            "checkmark": {
                "weight": "2px",
                "left": "6px",
                "top": "10%"
            },
            "control": {
                "border": "none",
                "colors": {
                    "bg": "#ecebea"
                },
                "focusVisible": {
                    "border": {
                        "color": "#0060D0"
                    }
                },
                "multiChoice": {
                    "borderRadius": "4px"
                },
                "width": "1.8em"
            },
            "padding": {
                "left": "4px",
                "top": "0.3em"
            }
        },
        "answerWithText": {
            "placeholder": "anythingElse",
        }
    },
    "header": {
        "bg": "#ffffff"
    },
    "darkTheme": {
        "button": {
            "bgColor": "#fdde55",
            "hover": {
                "bgColor": "#ffe055"
            },
            "disabled": {
                "bgColor": "rgba(255, 255, 255, 0.12)",
                "color": "#fff"
            },
            "focusVisible": {
                "border": {
                    "color": "#0060D0"
                }
            },
        },
        "colors": {
            "main": "#fdde55",
            "internal": "#000",
            "border": "rgba(255, 255, 255, 0.27)"
        },
        "emotion": {
            "focusVisible": {
                "border": {
                    "color": "#0060D0"
                }
            },
            "label": {
                "color": "rgba(255, 255, 255, 0.75)",
            },
            "selected": {
                "label": {
                    "color": "#fff"
                }
            },
            "hover": {
                "label": {
                    "color": "#fff"
                }
            }
        },
        "header": {
            "bg": "#677a9e33"
        },
        "input": {
            "colors": {
                "bg": "transparent",
                "placeholder": "rgba(255, 255, 255, 0.4)"
            },
            "focusVisible": {
                "border": {
                    "color": "#0060D0"
                }
            },
            "border": {
                "color": "rgba(255, 255, 255, 0.1)",
                "focusColor": "rgba(255, 255, 255, 0.1)",
                "hoverColor": "rgba(255, 255, 255, 0.1)",
                "type": "bottom"
            }
        },
        "markdown": {
            "href": {
                "color": "#62AAFD",
                "hoverColor": "#62AAFD",
            }
        },
        "rating": {
            "colors": {
                "borderInactive": "rgba(255, 255, 255, 0.3)",
                "borderInactiveHover": "rgba(255, 255, 255, 0.69)",
                "fillInactive": "transparent",
                "fillInactiveHover": "transparent"
            }
        },
        "scale": {
            "labels": {
                "color": "rgba(255, 255, 255, 0.48)"
            },
            "option": {
                "bg": "rgba(255, 255, 255, 0.06)",
                "color": "inherit",
                "focusVisible": {
                    "border": {
                        "color": "#0060D0"
                    }
                },
                "hoverBg": "rgba(255, 255, 255, 0.18)"
            }
        },
        "choice": {
            "option": {
                "control": {
                    "colors": {
                        "bg": "rgba(255, 255, 255, 0.06)"
                    },
                    "focusVisible": {
                        "border": {
                            "color": "#0060D0"
                        }
                    },
                }
            }
        },
        "questionCaption": {
            "color": "rgba(255, 255, 255, 0.69)"
        },
        "questionLabel": {
            "requiredAsteriskColor": "#FF3333"
        },
        "underlay": {
            "bg": "#323135"
        },
        "specialButtons": {
            "button": {
                "bg": {
                    "selected": "rgba(255, 255, 255, 0.18)",
                    "selectedHover": "rgba(255, 255, 255, 0.24)",
                },
                "color": "#fff",
                "selectedColor": "#fff"
            }
        }
    },
    "emotion": {
        "border": {
            "width": "0"
        },
        "block": {
            "count2maxWidth": "240px",
            "count3maxWidth": "360px",
            "maxWidth": "470px",
            "position": "center",
            "innerPosition": "space-between"
        },
        "focusVisible": {
            "border": {
                "color": "#0060D0"
            }
        },
        "height": "72px",
        "preset": "portal",
        "width": "72px",
        "selected": {
            "scale": 1.08,
            "label": {
                "color": "#000"
            }
        },
        "hover": {
            "scale": 1.08,
            "label": {
                "color": "#000"
            }
        },
        "label": {
            "color": "rgba(0, 0, 0, 0.5)",
            "fontSize": "14px",
            "fontWeight": "400",
            "lineHeight": "18px",
            "marginTop": "2px"
        }
    },
    "error": {
        "margin": "4px 0 0 0"
    },
    "finalPage": {
        "fontSize": "18px",
        "fontWeight": "500",
        "lineHeight": "24px",
        "padding": "100px"
    },
    "input": {
        "colors": {
            "bg": "transparent",
            "placeholder": "#999"
        },
        "focusVisible": {
            "border": {
                "color": "#0060D0"
            }
        },
        "autogrow": true,
        "border": {
            "bottomMargin": "0 4px 0 0",
            "type": "bottom",
            "color": "rgba(0, 0, 0, 0.1)",
            "focusColor": "rgba(0, 0, 0, 0.1)",
            "hoverColor": "rgba(0, 0, 0, 0.3)",
        },
        "padding": "0",
        "fontSize": "15px",
        "textarea": {
            "fontSize": "15px",
            "lineHeight": "20px",
            "maxHeight": "200px"
        },
        "restriction": {
            "hidden": true
        }
    },
    "markdown": {
        "fontSize": "16px",
        "fontWeight": "500",
        "lineHeight": "20px",
        "h1FontSize": "18px",
        "h1FontWeight": "500",
        "h1LineHeight": "24px",
        "h2FontSize": "16px",
        "h2FontWeight": "500",
        "h2LineHeight": "20px",
        "href": {
            "color": "#0060D0",
            "hoverColor": "#0060D0",
            "textDecoration": "underline"
        }
    },
    "on": "yes",
    "questionCaption": {
        "color": "#777",
        "fontSize": "1.3em",
        "margin": {
            "bottom": "1.9em",
            "top": "0.8em"
        },
        "padding": {
            "left": "4px"
        }
    },
    "questionLabel": {
        "fontSize": "9px",
        "fontWeight": "500",
        "lineHeight": "20px",
        "requiredAsteriskColor": "#FF3333"
    },
    "rating": {
        "block": {
            "count2maxWidth": "180px",
            "count3maxWidth": "360px",
            "maxWidth": "470px",
            "position": "center",
            "innerPosition": "space-between"
        },
        "focusVisible": {
            "border": {
                "color": "#0060D0"
            }
        },
        "colors": {
            "borderInactive": "rgba(0, 0, 0, 0.3)",
            "borderInactiveHover": "rgba(0, 0, 0, 0.66)",
        },
        "starType": "portal"
    },
    "scale": {
        "block": {
            "count2maxWidth": "180px",
            "count3maxWidth": "360px",
            "maxWidth": "470px",
            "position": "center"
        },
        "padding": {
            "inline": {
                "ifFew": "9px",
                "ifMany": "0",
            }
        },
        "labels": {
            "color": "#777",
            "fontSize": "14px",
            "lineHeight": "18px",
            "margin": {
                "bottom": "12px",
                "top": "2em"
            }
        },
        "option": {
            "bg": "#ecebea",
            "fontSize": "1.09em",
            "hoverBg": "#d1d1d1",
            "lineHeight": "2.2em",
            "width": "2.2em",
            "border": {
                "radius": "50%",
                "size": "0"
            },
            "mobile": {
                "fontSize": "5.2vw",
                "many": {
                    "fontSize": "3.963vw",
                    "labelWidth": "1.7em",
                    "lineHeight": "1.7em",
                    "marginBottom": "9px"
                }
            }
        }
    },
    "scroll": {
        "marginRight": "-12px",
        "paddingRight": "16px",
    },
    "specialButtons": {
        "marginTop": "10px",
        "button": {
            "bg": {
                "base": "rgba(255, 255, 255, 0.06)",
                "hover": "rgba(255, 255, 255, 0.18)",
                "selected": "rgba(0, 0, 0, 0.06)",
                "selectedHover": "rgba(0, 0, 0, 0.18)"
            },
            "border": {
                "colors": {
                    "bg": "rgba(51, 51, 51, 0.12)",
                    "hover": "rgba(51, 51, 51, 0.18)",
                    "selected": "rgba(0, 0, 0, 0.06)",
                },
                "radius": "12px",
                "width": "1px"
            },
            "fontSize": "14px",
            "lineHeight": "18px",
            "minHeight": "36px",
            "minWidth": "min(470px, 100%)",
            "textMargin": "9px 20px",
            "margin": "10px 0 0"
        },
        "otherAnswerTop": "8px"
    },
    "spinner": {
        "view": "portal"
    },
    "tooltip": {
        "top": "90%"
    },
    "underlay": {
        "bg": "#fff",
        "gap": "24px",
        "height": "100%",
        "mobile": {
            "padding": "2em"
        },
        "padding": "24px 20px 18px",
        "special": {
            "safariInTelegramPaddingBottom": "18px"
        }
    },
    "widget": {
        "fontWeight": "500",
    }
};
