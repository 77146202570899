"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .watermark {
        pointer-events: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;

        &__row {
            height: 200px;
            width: 2500px;
            top: -800px;
            left: calc(50% - 1150px);
            overflow: hidden;
            transform: rotate(-45deg);
            position: relative;
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            text-align: center;
            color: rgba(176,189,214,0.2);
        }
    }

    [dir=rtl] .watermark__row {
        left: auto;
        right: calc(50% - 1280px);
    }
`;
