"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCharLimits = exports.useNextBtnBehaviour = void 0;
const react_redux_1 = require("react-redux");
const selectors_1 = require("src/redux/sagas/selectors");
function useNextBtnBehaviour() {
    return (0, react_redux_1.useSelector)((state) => {
        var _a;
        return (_a = (0, selectors_1.getNextBtnBehaviour)(state)) !== null && _a !== void 0 ? _a : 'visibleAndEnabled';
    });
}
exports.useNextBtnBehaviour = useNextBtnBehaviour;
function useCharLimits() {
    return (0, react_redux_1.useSelector)((state) => {
        return state.survey.charLimits || {};
    });
}
exports.useCharLimits = useCharLimits;
