"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const default_styles_1 = require("src/components/app/themes/default.styles");
function compilePadding(padding, prefix) {
    const [top, right = top, bottom = top, left = right] = padding.split(' ');
    const pfx = prefix ? `-${prefix}` : '';
    return `
        --page-padding${pfx}-top: ${top};
        --page-padding${pfx}-right: ${right};
        --page-padding${pfx}-bottom: ${bottom};
        --page-padding${pfx}-left: ${left};
    `;
}
// eslint-disable-next-line complexity
function compileUnderlayStyleString(branding) {
    const { underlay = {} } = branding;
    const { bg = default_styles_1.LIGHT_THEME_PAGE_BG_COLOR, gap, height, margin, padding, mobile: { margin: mobileMargin, padding: mobilePadding } = {}, special: { safariInTelegramPaddingBottom } = {} } = underlay;
    return `{
        ${bg ? `--page-bg: ${bg};` : ''}
        ${gap ? `--page-gap: ${gap};` : ''}
        ${height ? `--page-height: ${height};` : ''}
        ${safariInTelegramPaddingBottom ? `--page-safari-in-telegram-padding-bottom: ${safariInTelegramPaddingBottom};` : ''}
        ${padding ? compilePadding(padding) : ''}
        ${mobilePadding ? compilePadding(mobilePadding, 'mobile') : ''}
        ${margin ? `--page-margin: ${margin};` : ''}
        ${mobileMargin ? `--page-margin-mobile: ${mobileMargin};` : ''}
    }`;
}
exports.default = compileUnderlayStyleString;
