"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    &.tumbler {
        display: inline-block;
    }

    .tumbler__switch {
        background: rgba(165, 177, 202, 0.3);
        height: 18px;
        width: 34px;
        border-radius: 11px;
        border: 1px solid rgba(165, 177, 202, 0.2);
    }

    .tumbler__switch_isOn {
        background: #ffdd57;
        border: 1px solid #ffd21f;
    }

    .tumbler__toggler-label {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &[dir='rtl'] .tumbler__toggler-label {
        flex-direction: row-reverse;
    }

    .tumbler__toggler-label:hover {
        cursor: pointer;
    }

    .tumbler__label-text {
        margin-right: 12px;
        font-size: 13px;
        white-space: nowrap;
    }

    .tumbler__knob {
        position: relative;
        width: 18px;
        height: 18px;
        background: white;
        border-radius: 50%;
        left: 0;
        transition: margin 0.3s ease-out;
    }

    .tumbler__switch_isOn .tumbler__knob {
        margin-inline: 16px;
    }
`;
