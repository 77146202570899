"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.finishSurveyWithQuery = void 0;
const effects_1 = require("redux-saga/effects");
const actions_1 = require("../actions");
const selectors_1 = require("./selectors");
const consts_1 = require("./consts");
function* finishSurveyWithQuery() {
    const answersFromQueryParams = yield (0, effects_1.select)(selectors_1.getQueryParamsAnswers);
    const finishFromQueryParams = yield (0, effects_1.select)(selectors_1.getQueryParamsFinish);
    if (answersFromQueryParams && answersFromQueryParams.length > 0 && finishFromQueryParams) {
        // @ts-expect-error TS(2345): Argument of type '{ to: number; }' is not assignab... Remove this comment to see the full error message
        yield (0, effects_1.put)((0, actions_1.moveNextPage)({ to: -1 }));
    }
}
exports.finishSurveyWithQuery = finishSurveyWithQuery;
function* router() {
    yield (0, effects_1.takeEvery)(consts_1.SURVEY_SUCCESSFUL_LOAD_ACTIONS, finishSurveyWithQuery);
}
exports.router = router;
