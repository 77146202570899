"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .spinner {
        text-align: center;
    }
    .spinner__circle {
        display: inline-block;
        width: 64px;
        height: 64px;
        animation: spinneranimation 0.4s linear infinite;
        animation-name: spinneranimation !important;
        transform: rotateZ(0);
    }

    @keyframes spinneranimation {
        to {
            transform: rotateZ(1turn);
        }
    }
`;
