"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .sidebyside__options {
        width: 100%;
        position: relative;
    }

    .sidebyside__option {
        position: relative;
        cursor: pointer;
        display: inline-block;
        box-sizing: border-box;
        border-radius: 0.3em;
        width: 50%;
        border: 0.4em solid transparent;
        max-height: 22.5em;
        overflow: hidden;
    }

    .sidebyside__option.checked {
        border: 0.4em solid var(--view-default-fill-color-action);
    }

    .sidebyside__image {
        display: block;
        box-sizing: border-box;
        margin: 0 auto;
        border-radius: 0.3em;
        max-width: 100%;
    }

    .sidebyside__image.cropped {
        mask-image: linear-gradient(rgb(0, 0, 0) 0, rgb(0, 0, 0) 18em, rgba(0, 0, 0, 0) 22.5em)
    }

    .sidebyside__buttons-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sidebyside__buttons {
        max-width: 100%;
    }

    .sidebyside__buttons .button {
        display: block;
        overflow: hidden;
        margin: 1em 0 0;
        width: 100%;
    }

    .sidebyside__button span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .sidebyside__zoom {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .desktop .sidebyside__zoom:hover {
        background-color: rgba(100, 100, 100, 0.7);
    }

    .desktop .sidebyside__zoom:hover ~ .sidebyside__option.checked {
        outline: none;
    }

    .sidebyside__icon-wrapper {
        display: inline-block;
        height: 100em;
        max-height: 50%;
        transform: translate(0, 50%);
        text-align: center;
        width: 100%;
    }

    .sidebyside__icon-wrapper:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    .sidebyside__icon-wrapper img {
        max-width: 50%;
        height: 100%;
        vertical-align: middle;
        border-radius: 1em;
        display: none;
    }

    .desktop .sidebyside__zoom:hover .sidebyside__icon-wrapper img {
        display: inline-block;
    }

    .sidebyside__modal-root::backdrop {
        background: rgba(0, 0, 0, 0.2);
        box-shadow: rgb(0 0 0 / 30%) 0px 0px 10px;
    }
    .sidebyside__modal-root {
        border: none;
        padding: 5em 1em 2em;
        max-width: 90%;
    }

    .sidebyside__modal-icon {
        all: unset;
        cursor: pointer;
        position: absolute;
        width: 5em;
        height: 5em;
        right: 0;
        top: 0;
    }
    .sidebyside__modal-icon:before,
    .sidebyside__modal-icon:after {
        position: absolute;
        left: 2.5em;
        top: 0;
        content: ' ';
        height: 5em;
        width: 0.2em;
        background-color: #333;
    }

    .sidebyside__modal-icon:before {
        transform: rotate(45deg);
    }

    .sidebyside__modal-icon:after {
        transform: rotate(-45deg);
    }

    .sidebyside__modal {
        max-width: 100%;
    }

    .sidebyside__modal-option {
        all: unset;
        width: 50%;
        box-sizing: border-box;
        padding: 1em;
        display: inline-block;
    }

    .sidebyside__modal-option:hover {
        background: #fff6d6;
    }

    .sidebyside__modal-option.checked {
        background: var(--view-default-fill-color-action);
    }

    .sidebyside__modal-image {
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.5);
        display: block;
        max-width: 100%;
    }

    .sidebyside__modal-buttons-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .phone .sidebyside__buttons {
        display: block;
    }

    .phone .sidebyside__buttons-wrapper {
        display: block;
    }

    .disable-tap-zoom {
        touch-action: manipulation;
    }

    .question_theme_ugc-cab,
    .question_theme_ugc-cab .question__content,
    .question_theme_ugc-cab .question__content-wrapper,
    .question_theme_ugc-cab .sidebyside {
        height: 100%;
    }

    .question_theme_ugc-cab .sidebyside {
        display: flex;
        flex-direction: column;
    }

    .question_theme_ugc-cab .sidebyside__option-container {
        margin-bottom: -16px;

        flex: 1;
        overflow-y: scroll;
    }

    .question_theme_ugc-cab .sidebyside__option-image {
        margin-bottom: 16px;

        display: flex;

        width: 100%;
    }

    .question_theme_ugc-cab .sidebyside__bottom-controls {
        padding: 20px 24px 8px;

        display: flex;
        flex-direction: column;
        align-items: stretch;

        color: #fff;
        background: #222;
        border-radius: 16px 16px 0 0;

        z-index: 1;
    }

    .question_theme_ugc-cab .sidebyside__question-label {
        margin-bottom: 12px;

        color: #e0e0e0;
    }

    .question_theme_ugc-cab .sidebyside__question-label p {
        margin: 0;

        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
    }

    .question_theme_ugc-cab .sidebyside__option-switch {
        padding: 5px;

        display: flex;

        background: #565656;
        border-radius: 6px;
    }

    .question_theme_ugc-cab .sidebyside__option-switch input {
        display: none;
    }

    .question_theme_ugc-cab .sidebyside__option-button {
        padding: 15px 4px;

        flex: 1;

        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
        text-align: center;

        color: #fff;
        background: transparent;
        border-radius: 4px;

        cursor: pointer;
    }

    .question_theme_ugc-cab .sidebyside__option-button_checked {
        color: #000;
        background: #fff;
    }

    .question_theme_ugc-cab .sidebyside__option-button_active {
        color: #ffd600;
    }

    .question_theme_ugc-cab .sidebyside__no-answer-button {
        padding: 12px 4px;

        flex: 1;

        font-size: 16px;
        line-height: 19px;

        color: #b4b4b4;
        background: transparent;
        border: 0;
        border-radius: 4px;

        outline: none;

        cursor: pointer;
    }

    .question_theme_ugc-cab .sidebyside__option-switch + .sidebyside__no-answer-button {
        margin-top: 8px;
    }
`;
