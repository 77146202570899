"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .step-paginator
    {
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;

        margin-top: 24px;

        text-align: center;
    }
`;
