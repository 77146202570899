"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function compileScrollStyleString(branding) {
    const { scroll = {} } = branding;
    const { marginRight, paddingRight, thumbColor, trackColor } = scroll;
    return `
        {
            ${marginRight ? `--scroll-margin-right: ${marginRight};` : ''}
            ${paddingRight ? `--scroll-padding-right: ${paddingRight};` : ''}
            ${thumbColor ? `--long-text-textarea-scrollbar-thumb-color: ${thumbColor};` : ''}
            ${trackColor ? `--long-text-textarea-scrollbar-track-color: ${trackColor};` : ''}
        }
    `;
}
exports.default = compileScrollStyleString;
