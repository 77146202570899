"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTextQuestion = exports.isLongTextQuestion = exports.isShortTextQuestion = exports.isQuestionWithOtherAnswerWithText = exports.isChoiceQuestion = exports.isQuestionWithOptions = exports.isTableQuestion = exports.EMOTION_OPTIONS = exports.SCALE_VALUES = exports.QuestionTypes = void 0;
exports.QuestionTypes = {
    CHOICE: 'choice',
    CLICKMAP_VIDEO: 'clickmapVideo',
    EMAIL: 'email',
    EMOTION: 'emotion',
    GEO_TEXT: 'geoText',
    LONG_TEXT: 'longText',
    NUMBER: 'number',
    RATING: 'rating',
    SCALE: 'scale',
    SHORT_TEXT: 'shortText',
    SIDE_BY_SIDE: 'sidebyside',
    UX: 'ux',
    TABLE: 'table',
    TABLE_SCALE: 'tableScale',
    DATE: 'date'
};
const TextQuestionTypes = [
    exports.QuestionTypes.DATE,
    exports.QuestionTypes.EMAIL,
    exports.QuestionTypes.GEO_TEXT,
    exports.QuestionTypes.LONG_TEXT,
    exports.QuestionTypes.NUMBER,
    exports.QuestionTypes.SHORT_TEXT
];
const MIN_SCALE_VALUES = [0, 1];
const MAX_SCALE_VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
exports.SCALE_VALUES = Array.from(new Set([...MIN_SCALE_VALUES, ...MAX_SCALE_VALUES]));
exports.EMOTION_OPTIONS = {
    2: [1, 5],
    3: [1, 3, 5],
    5: [1, 2, 3, 4, 5]
};
function isTableQuestion(q) {
    const qType = q.type;
    return qType === 'table' || qType === 'tableScale';
}
exports.isTableQuestion = isTableQuestion;
function isQuestionWithOptions(q) {
    return 'options' in q;
}
exports.isQuestionWithOptions = isQuestionWithOptions;
function isChoiceQuestion(q) {
    return q.type === exports.QuestionTypes.CHOICE;
}
exports.isChoiceQuestion = isChoiceQuestion;
function isQuestionWithOtherAnswerWithText(q) {
    return (q.type === exports.QuestionTypes.CHOICE ||
        q.type === exports.QuestionTypes.SCALE ||
        q.type === exports.QuestionTypes.RATING ||
        q.type === exports.QuestionTypes.EMOTION);
}
exports.isQuestionWithOtherAnswerWithText = isQuestionWithOtherAnswerWithText;
function isShortTextQuestion(q) {
    return q.type === exports.QuestionTypes.SHORT_TEXT;
}
exports.isShortTextQuestion = isShortTextQuestion;
function isLongTextQuestion(q) {
    return q.type === exports.QuestionTypes.LONG_TEXT;
}
exports.isLongTextQuestion = isLongTextQuestion;
function isTextQuestion(q) {
    return TextQuestionTypes.includes(q.type);
}
exports.isTextQuestion = isTextQuestion;
