"use strict";
/* eslint-disable camelcase */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PARAMS = exports.TYPES = void 0;
const TYPES = {
    banner: {
        payload: {
            displayMode: 'banner',
            localStorage: { enable: false },
            footer: { enable: false },
            header: { enable: false },
            pagination: { enable: false },
            progressBar: { enable: false },
            buttonNext: { theme: 'fixed' }
        },
        check: /\/poll\/banner\//
    },
    video: {
        payload: {
            displayMode: 'video',
            localStorage: { enable: false },
            footer: { enable: false },
            header: { enable: false },
            pagination: { enable: false },
            progressBar: { enable: false },
            theme: 'transparent',
            vendorLabel: { enable: false },
            buttonNext: {
                theme: 'action',
                size: 'l'
            }
        },
        check: /\/poll\/video\//
    },
    'ugc-cab': {
        payload: {
            theme: 'ugc-cab',
            localStorage: { enable: false },
            footer: { enable: false },
            header: { enable: false },
            progressBar: { enable: false },
            buttonNext: {
                theme: 'normal',
                size: 'l'
            }
        },
        check: /\/poll\/ugc-cab\//
    },
    feedback: {
        payload: {
            api: {
                path: '/poll/feedback/api/v0'
            },
            forceSupportDarkMode: true
        },
        check: /\/poll\/feedback\//
    }
};
exports.TYPES = TYPES;
const PARAMS = {
    _ls_enable_: {
        type: 'boolean',
        path: 'localStorage.enable'
    },
    _header_enable_: {
        type: 'boolean',
        path: 'header.enable'
    },
    _show_hidden_questions_: {
        type: 'boolean',
        path: 'hidden.enable'
    },
    _footer_enable_: {
        type: 'boolean',
        path: 'footer.enable'
    },
    _page_id_: {
        type: 'string',
        path: 'pageId'
    },
    _theme_: {
        type: 'string',
        path: 'theme'
    },
    _background_: {
        type: 'string',
        path: 'background'
    },
    _pagination_enable_: {
        type: 'boolean',
        path: 'pagination.enable'
    },
    _progress_bar_enable_: {
        type: 'boolean',
        path: 'progressBar.enable'
    },
    _vendor_label_enable_: {
        type: 'boolean',
        path: 'vendorLabel.enable'
    },
    _button_next_theme_: {
        type: 'string',
        path: 'buttonNext.theme'
    },
    from_player: {
        type: 'boolean',
        path: 'fromPlayer'
    },
    _skip_button_: {
        type: 'boolean',
        path: 'skipButton.enable'
    },
    _spinner_color_: {
        type: 'string',
        path: 'spinner.color'
    },
    _embedded_: {
        type: 'boolean',
        path: 'embedded'
    }
};
exports.PARAMS = PARAMS;
