"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .attachment-image {
        position: relative;
        display: flex;
        justify-content: var(--attachment-justify-content);
    }

    .attachment-image img {
        margin-bottom: var(--attachment-margin-bottom);

        display: block;

        max-width: 100%;
        max-height: calc(0.5 * var(--vh, 100vh));
    }

    .question_attachment_bottom .attachment-image {
        margin-top: var(--attachment-margin-top);
    }

    .attachment-image__wrapper {
        position: relative;
        display: inline-block;
        vertical-align: top;
    }

    .attachment-image__wrapper_zoomable:hover {
        cursor: pointer;
    }

    .attachment-image__zoom {
        position: absolute;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMjUgMTIuNUMyLjc5ODIyIDEyLjUgMCA5LjcwMTc4IDAgNi4yNUMwIDIuNzk4MjIgMi43OTgyMiAwIDYuMjUgMEM5LjcwMTc4IDAgMTIuNSAyLjc5ODIyIDEyLjUgNi4yNUMxMi41IDcuNjE0OTcgMTIuMDYyNCA4Ljg3Nzc1IDExLjMxOTkgOS45MDU3TDE1LjIwNzEgMTMuNzkyOUMxNS41OTc2IDE0LjE4MzQgMTUuNTk3NiAxNC44MTY2IDE1LjIwNzEgMTUuMjA3MUMxNC44MTY2IDE1LjU5NzYgMTQuMTgzNCAxNS41OTc2IDEzLjc5MjkgMTUuMjA3MUw5LjkwNTcgMTEuMzE5OUM4Ljg3Nzc1IDEyLjA2MjQgNy42MTQ5NyAxMi41IDYuMjUgMTIuNVpNNi4yNSAxMC41QzguNTk3MjEgMTAuNSAxMC41IDguNTk3MjEgMTAuNSA2LjI1QzEwLjUgMy45MDI3OSA4LjU5NzIxIDIgNi4yNSAyQzMuOTAyNzkgMiAyIDMuOTAyNzkgMiA2LjI1QzIgOC41OTcyMSAzLjkwMjc5IDEwLjUgNi4yNSAxMC41WiIgZmlsbD0iIzJGMzc0NyIvPgo8L3N2Zz4K');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 15px;
        background-color: #f6f8fb;
        border-radius: 12px;
        width: 36px;
        height: 36px;
        top: 10px;
        display: none;
        right: 10px;
    }

    .attachment-image__wrapper:hover > .attachment-image__zoom {
        display: block;
    }
`;
