"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileCaptionStyleString(branding) {
    const { questionCaption = {} } = branding;
    const { color, fontSize, fontWeight, margin: { bottom, top } = {}, padding: { left } = {} } = questionCaption;
    return `
        {
            ${color ? `--question-caption-color: ${color};` : ''}
            ${fontSize ? `--question-caption-font-size: ${fontSize};` : ''}
            ${fontWeight ? `--question-caption-font-weight: ${fontWeight};` : ''}
            ${bottom ? `--question-caption-margin-bottom: ${bottom};` : ''}
            ${top ? `--question-caption-margin-top: ${top};` : ''}
            ${left ? `--question-caption-padding-left: ${left};` : ''}
        }
    `;
}
exports.default = compileCaptionStyleString;
