"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    html
    {
        height: 100%;
    }

    .app_theme_ux
    {
        /* Page */
        & .page
        {
            padding: 0;
            display: block;

            & .page__questions
            {
                display: block;
            }
        }

        /* Widget */
        & .widget-markup
        {
            margin: 0;
        }

        & .widget-markup:first-child
        {
            margin-top: 16px;
        }

        & .widget-markup .widget-markup__label
        {
            text-align: center;
        }

        & .widget-markup .widget-markup__label .markdown p
        {
            font-size: 20px;
            line-height: 30px;
            color: #000;

            max-width: 650px;
            margin: 0 auto;
        }

        /* Question */
        & .widget-markup + .question
        {
            margin: 24px 0 32px;
        }

        & .question .question__label + .question-image-clickmap
        {
            margin: 32px auto;
        }

        & .question_type_ux .question__label{
            text-align: center;
        }

        & .question_type_ux .question__label p
        {
            display: inline-block;
            font-size: 15px;
            line-height: 22px;
            color: #000;
            margin: 0;
            padding: 5px 13px;
            max-width: 650px;
            background: rgba(0, 0, 0, 0.07);
            border-radius: 8px;
            hyphens:auto;

        }

        & .question__label .markdown > *{
            margin-bottom: 1.5em;
            text-align: start;
        }

        & .header {
            height: 20px;
        }

        &.app_withHeader {
            padding-top: 100px;
        }
        }
    }
`;
