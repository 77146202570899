"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    position: fixed;
    left: 0;
    right: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    margin: auto;
    padding: 1em;
    background: transparent;
    display: block;

    &:not([open]) {
        display: none;
    }

    &.image-modal__dialog_isPolyfill {
        background: rgba(50, 50, 50, 0.4);
        position: fixed !important;
        top: 50% !important;
        transform: translate(0, -50%) !important;
    }

    & + .backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &::backdrop {
        background: rgba(50, 50, 50, 0.4);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    ._dialog_overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &[open] {
        inset-block-start: 0px;
        inset-block-end: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        border: none;
        margin: 0px;
    }

    .image-modal__wrapper {
        background-color: white;
        border-radius: 4px;
        position: relative;
        max-height: 80vh;
        max-width: 80vw;
        overflow: auto;
        overflow-x: hidden;
        position: relative;
    }
    .image-modal__wrapper_isPolyfill {
        background-color: transparent;
    }

    .image-modal__image {
        max-height: 90vh;
        max-width: 80vw;
        border-radius: 4px;
        display: block;
    }

    .image-modal__cross {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjIwNzEgMi4yMDcxMUMxNS41OTc2IDEuODE2NTggMTUuNTk3NiAxLjE4MzQyIDE1LjIwNzEgMC43OTI4OTNDMTQuODE2NiAwLjQwMjM2OSAxNC4xODM0IDAuNDAyMzY5IDEzLjc5MjkgMC43OTI4OTNMOCA2LjU4NTc5TDIuMjA3MTEgMC43OTI4OTNDMS44MTY1OCAwLjQwMjM2OSAxLjE4MzQyIDAuNDAyMzY5IDAuNzkyODkzIDAuNzkyODkzQzAuNDAyMzY5IDEuMTgzNDIgMC40MDIzNjkgMS44MTY1OCAwLjc5Mjg5MyAyLjIwNzExTDYuNTg1NzkgOEwwLjc5Mjg5MyAxMy43OTI5QzAuNDAyMzY5IDE0LjE4MzQgMC40MDIzNjkgMTQuODE2NiAwLjc5Mjg5MyAxNS4yMDcxQzEuMTgzNDIgMTUuNTk3NiAxLjgxNjU4IDE1LjU5NzYgMi4yMDcxMSAxNS4yMDcxTDggOS40MTQyMUwxMy43OTI5IDE1LjIwNzFDMTQuMTgzNCAxNS41OTc2IDE0LjgxNjYgMTUuNTk3NiAxNS4yMDcxIDE1LjIwNzFDMTUuNTk3NiAxNC44MTY2IDE1LjU5NzYgMTQuMTgzNCAxNS4yMDcxIDEzLjc5MjlMOS40MTQyMSA4TDE1LjIwNzEgMi4yMDcxMVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 15px;
        width: 15px;
        height: 15px;
        margin-left: 48px;
        opacity: 0.8;
    }

    .image-modal__zoom-container {
        all: unset;
    }

    .image-modal__zoom-in {
        background-image: url('data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHJva2U9IiNmZmZmZmYiPgoNPGcgaWQ9IlNWR1JlcG9fYmdDYXJyaWVyIiBzdHJva2Utd2lkdGg9IjAiLz4KDTxnIGlkPSJTVkdSZXBvX3RyYWNlckNhcnJpZXIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgoNPGcgaWQ9IlNWR1JlcG9faWNvbkNhcnJpZXIiPiA8cGF0aCBkPSJNMjAgMjBMMTQuOTQ5NyAxNC45NDk3TTE0Ljk0OTcgMTQuOTQ5N0MxNi4yMTY1IDEzLjY4MyAxNyAxMS45MzMgMTcgMTBDMTcgNi4xMzQwMSAxMy44NjYgMyAxMCAzQzYuMTM0MDEgMyAzIDYuMTM0MDEgMyAxMEMzIDEzLjg2NiA2LjEzNDAxIDE3IDEwIDE3QzExLjkzMyAxNyAxMy42ODMgMTYuMjE2NSAxNC45NDk3IDE0Ljk0OTdaTTcgMTBIMTNNMTAgN1YxMyIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+IDwvZz4KDTwvc3ZnPg==');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 25px;
        width: 25px;
        height: 25px;
        opacity: 0.8;
    }

    .image-modal__zoom-in:hover,
    .image-modal__zoom-out:hover,
    .image-modal__cross:hover {
        cursor: pointer;
        opacity: 1;
    }

    .image-modal__zoom-out {
        background-image: url('data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHJva2U9IiNmZmZmZmYiPgoNPGcgaWQ9IlNWR1JlcG9fYmdDYXJyaWVyIiBzdHJva2Utd2lkdGg9IjAiLz4KDTxnIGlkPSJTVkdSZXBvX3RyYWNlckNhcnJpZXIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgoNPGcgaWQ9IlNWR1JlcG9faWNvbkNhcnJpZXIiPiA8cGF0aCBkPSJNMjAgMjBMMTQuOTQ5NyAxNC45NDk4TTE0Ljk0OTcgMTQuOTQ5OEMxNi4yMTY1IDEzLjY4MyAxNyAxMS45MzMgMTcgMTBDMTcgNi4xMzQwMSAxMy44NjYgMyAxMCAzQzYuMTM0MDEgMyAzIDYuMTM0MDEgMyAxMEMzIDEzLjg2NiA2LjEzNDAxIDE3IDEwIDE3QzExLjkzMyAxNyAxMy42ODMgMTYuMjE2NSAxNC45NDk3IDE0Ljk0OThaTTcgMTBIMTMiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPiA8L2c+Cg08L3N2Zz4=');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 25px;
        width: 25px;
        height: 25px;
        opacity: 0.8;
    }

    .image-modal__icons {
        position: absolute;
        top: 44px;
        right: 44px;
        display: flex;
        align-items: center;
    }

    .image-modal__image_zoom {
        max-height: none;
        max-width: none;
    }
    .image-modal__wrapper_zoom {
        overflow-x: visible;
    }

    .image-modal__image_isVertical {
        max-height: none;
    }

    @media (max-width: 640px) {
        .image-modal__icons {
            top: 20px;
            right: 20px;
        }
    }
`;
