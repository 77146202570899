"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .final {
        font-family: var(--main-font);
        font-size: var(--page-final-font-size);
        font-weight: var(--page-final-font-weight);
        line-height: var(--page-final-line-height);
        padding-block: var(--page-final-padding);
        text-align: center;
    }

    .final_theme_ugc-cab {
        padding: 24px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        text-align: start;

        box-sizing: border-box;
    }

    .final_theme_ugc-cab span {
        font-size: 15px;
        line-height: 18px;
    }

    .final_theme_ugc-cab h1 {
        font-size: 28px;
        line-height: 33px;
    }
`;
