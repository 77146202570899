"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
function brandingFooterStyles() {
    return (0, react_1.css) `
        @media (max-width: 768px) {
            .branding__footer,
            .branding__footer-links {
                flex-direction: column;
                align-items: flex-start;
            }

            .branding__footer-links {
                margin-bottom: 8px;
            }

            .branding__footer-link + .branding__footer-link {
                margin-left: 0;
                margin-top: 8px;
            }
        }
    `;
}
exports.default = brandingFooterStyles;
