"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.checkShouldSaveToCookies = exports.saveSurveyStart = exports.saveSurveyFinish = void 0;
const effects_1 = require("redux-saga/effects");
const yc_cookie_1 = require("src/utils/yc-cookie"); // yc-cookie с долгим expires для вложенных значений
const selectors_1 = require("./selectors");
const consts_1 = require("./consts");
const ACTIONS = {
    start: 'start',
    finish: 'finish'
};
const projectName = 'pythia';
const parseCookieData = () => (0, yc_cookie_1.get)(projectName) || {};
const saveCookieData = data => (0, yc_cookie_1.set)(projectName, data);
// оставляем только последний символ из слага опроса для эксперимента, чтобы минимизировать длину куки
const getSurveySlugLastChar = slug => slug.slice(-1);
// храним в виде {'start':1608126898, '1':1, '2':1, '4':1},
// ключи из последних символов завершенных опросов, дата последнего старта в сек в поле start
const saveSurveyFinish = slug => {
    const lastCharFromSlug = getSurveySlugLastChar(slug);
    const data = parseCookieData();
    data[lastCharFromSlug] = 1;
    saveCookieData(data);
};
exports.saveSurveyFinish = saveSurveyFinish;
const saveSurveyStart = () => {
    const data = parseCookieData();
    const startTime = Math.floor(Date.now() / 1000);
    // @ts-ignore
    data.start = startTime;
    saveCookieData(data);
};
exports.saveSurveyStart = saveSurveyStart;
function* checkShouldSaveToCookies(action) {
    const { fromPlayer } = yield (0, effects_1.select)(selectors_1.getConfig);
    if (!fromPlayer) {
        return;
    }
    const slug = yield (0, effects_1.select)(selectors_1.getSlug);
    if (action === ACTIONS.start) {
        yield (0, effects_1.call)(exports.saveSurveyStart);
    }
    else {
        yield (0, effects_1.call)(exports.saveSurveyFinish, slug);
    }
}
exports.checkShouldSaveToCookies = checkShouldSaveToCookies;
function* router() {
    yield (0, effects_1.takeEvery)(consts_1.SURVEY_SUCCESSFUL_LOAD_ACTIONS, checkShouldSaveToCookies, ACTIONS.start);
    yield (0, effects_1.takeEvery)('SURVEY_FINISH_SUCCESS', checkShouldSaveToCookies, ACTIONS.finish);
    yield (0, effects_1.takeEvery)('SURVEY_SCREENOUT_SUCCESS', checkShouldSaveToCookies, ACTIONS.finish);
}
exports.router = router;
