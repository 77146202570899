"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.displayNotification = void 0;
const effects_1 = require("redux-saga/effects");
const redux_saga_1 = require("redux-saga");
const actions_1 = require("../actions");
function* displayNotification(action) {
    const { message } = action;
    yield (0, effects_1.call)(actions_1.addNotification, message);
    yield (0, effects_1.call)(redux_saga_1.delay, 2000);
    yield (0, effects_1.put)((0, actions_1.removeNotification)());
}
exports.displayNotification = displayNotification;
function* router() {
    yield (0, effects_1.takeLatest)('ADD_NOTIFICATION', displayNotification);
}
exports.router = router;
