"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProgressBarProps = void 0;
const react_redux_1 = require("react-redux");
const question_1 = require("src/utils/question");
// eslint-disable-next-line import/prefer-default-export
const useProgressBarProps = () => 
// eslint-disable-next-line max-statements, complexity
(0, react_redux_1.useSelector)((state) => {
    var _a;
    const { pages, questions, survey: { charLimits } } = state;
    const { currentPage, list } = pages;
    let completed = 0;
    const countQuestions = questions && Object.keys(questions).length;
    if (!countQuestions) {
        return {
            percent: 0,
            totalPages: 0,
            currentPageNumber: 0,
        };
    }
    // Если страницы закончили
    if (currentPage === -1) {
        return {
            percent: 100,
            totalPages: list.length,
            currentPageNumber: currentPage + 1,
        };
    }
    for (let i = 0; i < currentPage; i += 1) {
        completed += list[i].questionIds.length;
    }
    // eslint-disable-next-line complexity
    list[currentPage].questionIds.forEach((questionId) => {
        const question = (0, question_1.createQuestionModel)(questions, questionId, { charLimits });
        const value = question.getValueAsObj();
        if (('text' in value && value.text) ||
            ('options' in value && value.options && value.options.length > 0)) {
            completed += 1;
        }
    });
    return {
        percent: (_a = Math.floor((completed / countQuestions) * 100)) !== null && _a !== void 0 ? _a : 0,
        totalPages: list.length,
        currentPageNumber: currentPage + 1,
    };
});
exports.useProgressBarProps = useProgressBarProps;
