"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const errors_1 = require("src/utils/errors");
const error = () => next => action => {
    if (action.err) {
        (0, errors_1.sendError)(action.err);
    }
    return next(action);
};
exports.default = error;
