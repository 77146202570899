"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    html {
        height: 100%;
        /* скейлим 10x чтобы не упереться в минимальный размер шрифта */
        font-size: 100px;

        @media (max-width: 300px) {
            font-size: 80px;
        }
    }

    body {
        font-size: 0.1em; /* компенсируем 10x размер текста на html */
    }

    .app {
        position: relative;
    }

    .app__thanks {
        text-align: center;
        font-size: 3em;
    }

    @media (max-width: 640px) {
        .app_withHeader .pages {
            padding: 50px 16px 0px;
        }
    }
`;
