"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileInputBorder(border = {}) {
    const { width, radius, colors: { bg, hover, selected } = {} } = border;
    return `
        ${hover ? `--button-normal-border-hover-color: ${hover};` : ''}
        ${bg ? `--button-normal-border-color: ${bg};` : ''}
        ${selected ? `--button-normal-border-selected-color: ${selected};` : ''}
        ${radius ? `--button-normal-border-radius: ${radius};` : ''}
        ${width ? `--button-normal-dependent-border-width: ${width};` : ''}
    `;
}
// eslint-disable-next-line complexity
function compileInputStyleString(branding) {
    const { specialButtons = {} } = branding;
    const { button: { bg: { base, hover, selected, selectedHover } = {}, border, color, fontSize, lineHeight, margin, minHeight, minWidth, selectedColor, textMargin } = {}, marginTop, otherAnswerTop } = specialButtons;
    return `
        {
            ${base ? `--button-normal-bg: ${base};` : ''}
            ${hover ? `--button-normal-hover-bg: ${hover};` : ''}
            ${selected ? `--button-normal-selected-bg: ${selected};` : ''}
            ${selectedHover ? `--button-normal-selected-hover-bg: ${selectedHover};` : ''}
            ${fontSize ? `--button-dependent-font-size: ${fontSize};` : ''}
            ${color ? `--button-normal-color: ${color};` : ''}
            ${selectedColor ? `--button-normal-color-checked: ${selectedColor};` : ''}
            ${lineHeight ? `--button-normal-line-height: ${lineHeight};` : ''}
            ${minHeight ? `--special-buttons-min-height: ${minHeight};` : ''}
            ${minWidth ? `--special-buttons-min-width: ${minWidth};` : ''}
            ${otherAnswerTop ? `--special-buttons-other-answer-input-margin-top: ${otherAnswerTop};` : ''}
            ${marginTop ? `--special-buttons-margin-top: ${marginTop};` : ''}
            ${textMargin ? `--button-dependent-text-margin: ${textMargin};` : ''}
            ${margin ? `--special-button-margin: ${margin};` : ''}
            ${compileInputBorder(border)}
        }
    `;
}
exports.default = compileInputStyleString;
