"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
function brandingLogoStyles() {
    return (0, react_1.css) `
        .branding__logo {
            position: absolute;
            padding: 30px;
        }

        .branding__logo img {
            max-width: 300px;
            max-height: 80px;
        }

        .branding__logo_withHeader {
            top: 80px;
        }

        @media (max-width: 1550px) {
            .branding__logo {
                position: relative;
            }
        }

        @media (max-width: 640px) {
            .branding__logo {
                position: relative;
                top: 0;
            }
        }

        @media (max-width: 550px) {
            .branding__logo {
                padding: 1.5em;
            }
        }
    `;
}
exports.default = brandingLogoStyles;
