"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootProvider = exports.useRootService = exports.createRoot = exports.getRoot = exports.getRootService = exports.getRootFromPage = void 0;
const react_1 = require("react");
const effects_1 = require("redux-saga/effects");
const wrapperClassName = 'pythia-surveys-wrapper';
const rootClassName = 'pythia-surveys-root';
const rootSelector = `.${rootClassName}`;
const stylesRootClassName = 'pythia-surveys-styles-root';
const stylesRootSelector = `.${stylesRootClassName}`;
// в случае показа на отдельной странице, без встраивания/вне adcreative
const getRootFromPage = () => {
    return document.querySelector(rootSelector);
};
exports.getRootFromPage = getRootFromPage;
const getRootService = (root) => {
    if (!root) {
        root = (0, exports.getRootFromPage)();
    }
    return {
        get: () => root,
        getStylesRoot: () => {
            return window.Cypress // временный хак, иногда отваливаются e2e-тесты, похоже падают при встраивании стилей в div
                ? document.head
                : root.parentNode.querySelector(stylesRootSelector);
        }
    };
};
exports.getRootService = getRootService;
/** геттер для использования в сагах */
function* getRoot() {
    const rootService = yield (0, effects_1.getContext)('rootService');
    return rootService.get();
}
exports.getRoot = getRoot;
const createRoot = () => {
    const wrapper = document.createElement('div');
    wrapper.className = wrapperClassName;
    const stylesRoot = document.createElement('div');
    stylesRoot.className = stylesRootClassName;
    const root = document.createElement('div');
    root.className = rootClassName;
    wrapper.appendChild(stylesRoot);
    wrapper.appendChild(root);
    return { container: wrapper, root };
};
exports.createRoot = createRoot;
const RootContext = (0, react_1.createContext)(undefined);
const useRootService = () => {
    return (0, react_1.useContext)(RootContext);
};
exports.useRootService = useRootService;
exports.RootProvider = RootContext.Provider;
