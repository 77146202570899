"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function setTitle(title) {
    try {
        document.title = title;
    }
    catch (e) {
        console.error(e);
    }
}
exports.default = setTitle;
