"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function compileAttachmentStyleString(branding) {
    const { justify, marginBottom, marginTop } = branding.attachment || {};
    return `{
        ${justify ? `--attachment-justify-content: ${justify};` : ''}
        ${marginBottom ? `--attachment-margin-bottom: ${marginBottom};` : ''}
        ${marginTop ? `--attachment-margin-top: ${marginTop};` : ''}
    }`;
}
exports.default = compileAttachmentStyleString;
