"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const effects_1 = require("redux-saga/effects");
const cookie_1 = require("../cookie");
const finishWithQuery_1 = require("../finishWithQuery");
const iframe_1 = require("../iframe");
const init_1 = require("../init");
const lang_1 = require("../lang");
const local_storage_1 = require("../local-storage");
const log_answers_1 = require("../log-answers");
const logic_1 = require("../logic");
const metrika_1 = require("../metrika");
const redirects_1 = require("../redirects");
const scroll_1 = require("../scroll");
const scroll_detector_1 = require("../scroll-detector");
const title_1 = require("../title");
const transition_1 = require("../transition");
const transition_timer_1 = require("../transition-timer");
const image_click_1 = require("../image-click");
const display_hidden_questions_1 = require("../display-hidden-questions");
const notifications_1 = require("../notifications");
const window_1 = require("../window");
const check_active_revision_1 = require("../check-active-revision");
const api_1 = require("../api");
function* rootSaga({ store, lsClient }) {
    yield (0, effects_1.all)([
        (0, effects_1.fork)(api_1.watchRequests),
        (0, effects_1.fork)(cookie_1.router),
        (0, effects_1.fork)(iframe_1.router),
        (0, effects_1.fork)(image_click_1.router),
        (0, effects_1.fork)(init_1.router),
        (0, effects_1.fork)(lang_1.router),
        (0, effects_1.fork)(display_hidden_questions_1.router),
        (0, effects_1.fork)(log_answers_1.router),
        (0, effects_1.fork)(logic_1.router, store),
        (0, effects_1.fork)(redirects_1.router),
        (0, effects_1.fork)(transition_1.router),
        (0, effects_1.fork)(transition_timer_1.router),
        (0, effects_1.fork)(notifications_1.router),
        // второстепенные саги, при краше не должны крашить опрос
        (0, effects_1.spawn)(finishWithQuery_1.router),
        (0, effects_1.spawn)(local_storage_1.router, lsClient),
        (0, effects_1.spawn)(metrika_1.router),
        (0, effects_1.spawn)(scroll_1.router),
        (0, effects_1.spawn)(scroll_detector_1.router),
        (0, effects_1.spawn)(title_1.router),
        (0, effects_1.spawn)(window_1.router),
        (0, effects_1.spawn)(check_active_revision_1.router)
    ]);
}
exports.default = rootSaga;
