"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFinishRedirect = exports.useErrorRedirect = void 0;
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const frame_1 = require("src/utils/frame");
const actions_1 = require("src/redux/actions");
const lang_1 = require("./lang");
const defaultLandingRedirect = 'https://surveys.yandex.%s/poll-landing';
const getLandingRedirectUrl = (lang, landingStatus) => {
    const tld = lang === 'ru' ? 'ru' : 'com';
    const url = new URL(defaultLandingRedirect.replace('%s', tld));
    url.searchParams.set('status', landingStatus);
    /* дописываем lang, чтобы не полагаться на langdetect на лендинге */
    url.searchParams.set('lang', lang === 'ru' ? lang : 'en');
    return url.toString();
};
const genRetpath = (retpath, lang) => {
    const url = new URL(retpath);
    if (url.hostname.startsWith('surveys.yandex.') && url.pathname === '/poll-landing') {
        return getLandingRedirectUrl(lang, 'thanks');
    }
    return url.toString();
};
const useErrorRedirect = (errorStatus) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const lang = (0, lang_1.useSurveyLang)();
    (0, react_1.useEffect)(() => {
        const inFrame = (0, frame_1.isInIframe)();
        const url = getLandingRedirectUrl(lang, errorStatus);
        if (errorStatus && !inFrame) {
            dispatch((0, actions_1.doRedirect)(url));
        }
    }, [errorStatus, dispatch, lang]);
};
exports.useErrorRedirect = useErrorRedirect;
const useFinishRedirect = ({ status, isFinished, retpath }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const lang = (0, lang_1.useSurveyLang)();
    const inFrame = (0, frame_1.isInIframe)();
    (0, react_1.useEffect)(() => {
        if (inFrame) {
            return;
        }
        if (status === 302 && isFinished) {
            dispatch((0, actions_1.doRedirect)(genRetpath(retpath, lang)));
        }
    }, [status, isFinished, retpath, dispatch, inFrame, lang]);
};
exports.useFinishRedirect = useFinishRedirect;
