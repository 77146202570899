"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const errors_1 = require("./errors");
const DEFAULT_RETRIES = [1000, 3000, 10000];
class Client {
    constructor({ credentials = 'same-origin', headers, host, path = '', protocol = 'https://', retries }) {
        if (!host) {
            throw new Error('Did not pass host');
        }
        this.endpoint = `${protocol}${host}${path}`;
        this.host = host;
        this.protocol = protocol;
        this.path = path;
        this.credentials = credentials;
        this.retries = retries ? retries.slice() : DEFAULT_RETRIES.slice();
        this.headers = Object.assign({ 'Content-Type': 'application/json' }, headers);
    }
    // eslint-disable-next-line max-statements, complexity
    _doRequest(params) {
        const { action, method, data, endpoint } = params;
        let { queryString } = params;
        const { credentials, headers } = this;
        const retries = params.retries ? params.retries.slice() : DEFAULT_RETRIES.slice();
        const options = {
            method,
            credentials,
            headers
        };
        if (['POST', 'PUT'].includes(method)) {
            if (data) {
                options.body = JSON.stringify(data);
            }
        }
        queryString = (queryString && `?${queryString}`) || '';
        const url = `${endpoint || this.endpoint}${action}${queryString}`;
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line max-statements, complexity
            const doFetch = () => __awaiter(this, void 0, void 0, function* () {
                try {
                    const res = yield fetch(url, options);
                    const { status } = res;
                    if (status === 204) {
                        return resolve({ status });
                    }
                    const json = yield res.json();
                    // редиректим на капчу
                    if ('type' in json && json.type === 'captcha') {
                        const captchaUrl = json.captcha['captcha-page'];
                        const err = new Error('Received captcha response');
                        (0, errors_1.sendError)(err, { additional: json, type: 'captcha' });
                        location.href = captchaUrl;
                        return;
                    }
                    return resolve({ data: json, status });
                }
                catch (e) {
                    if (retries.length) {
                        return setTimeout(() => {
                            void doFetch();
                        }, retries.shift());
                    }
                    return reject(e);
                }
            });
            void doFetch();
        });
    }
    getSurvey({ slug, queryString, retries, data }) {
        const requestParams = {
            action: `/survey/${slug}/start`,
            method: 'POST',
            retries,
            queryString,
            data
        };
        return this._doRequest(requestParams);
    }
    startInterview({ slug, respReuse, redirects, pageOrder, revisionId, queryString, groupChildren, questionOptions }) {
        const requestParams = {
            action: `/survey/${slug}/startInterview`,
            method: 'POST',
            queryString,
            data: {
                respReuse,
                redirects,
                pageOrder,
                revisionId,
                groupChildren,
                questionOptions
            }
        };
        return this._doRequest(requestParams);
    }
    sendAnswer({ slug, data, retries }) {
        const requestParams = {
            action: `/survey/${slug}/answer`,
            method: 'POST',
            retries,
            data
        };
        return this._doRequest(requestParams);
    }
    sendAnswerBulk({ slug, data, retries }) {
        const requestParams = {
            action: `/survey/${slug}/answerBulk`,
            method: 'POST',
            retries,
            data
        };
        return this._doRequest(requestParams);
    }
    pageSwitch({ slug, data, retries }) {
        const requestParams = {
            action: `/survey/${slug}/pageSwitch`,
            method: 'POST',
            retries,
            data
        };
        return this._doRequest(requestParams);
    }
    finishSurvey({ slug, data, retries }) {
        const requestParams = {
            action: `/survey/${slug}/finish`,
            method: 'POST',
            retries,
            data
        };
        return this._doRequest(requestParams);
    }
    screenout({ slug, data, queryString, retries }) {
        const requestParams = {
            action: `/survey/${slug}/screenout`,
            method: 'POST',
            queryString,
            retries,
            data
        };
        return this._doRequest(requestParams);
    }
    getActiveRevisionId({ slug }) {
        const requestParams = {
            action: `/survey/${slug}/revisionId`,
            method: 'GET'
        };
        return this._doRequest(requestParams);
    }
}
exports.default = Client;
