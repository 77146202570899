"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.watchRequests = exports.handleRequest = exports.callApi = exports.waitForInterviewId = void 0;
const effects_1 = require("redux-saga/effects");
const api_client_1 = require("src/redux/store/api-client");
const actions_1 = require("../actions");
const selectors_1 = require("./selectors");
const isGetSurveyRequest = ({ type, method }) => type === 'REQUEST_TO_API' && method === 'getSurvey';
function* waitForInterviewId() {
    if (yield (0, effects_1.select)(selectors_1.getInterviewId)) {
        return;
    }
    while (true) {
        const action = yield (0, effects_1.take)('*');
        // Не ждем ivid для getSurvey
        if (isGetSurveyRequest(action)) {
            return;
        }
        if (yield (0, effects_1.select)(selectors_1.getInterviewId)) {
            return;
        }
    }
}
exports.waitForInterviewId = waitForInterviewId;
function getErrorCode(host) {
    // PYTHIADEV-289: На odna.co 404-я страница отдается без CORS-заголовков
    // для 'odna.co' не бывает 500
    return host === 'odna.co' ? 404 : 500;
}
function callApi(client, method, params) {
    return client[method](params);
}
exports.callApi = callApi;
// eslint-disable-next-line max-statements, complexity
function* handleRequest({ params, method, steps }) {
    var _a;
    const [PENDING, SUCCESS, FAILURE] = steps || [];
    const { survey } = yield (0, effects_1.select)(selectors_1.getState);
    const { _uid, _ivid, _seqnum } = survey;
    params.data = params.data || {};
    const { data } = params;
    // _uid прокидываем, только если он явно пришел с бэка!
    if (_uid) {
        data._uid = _uid;
    }
    data._ivid = _ivid;
    /**
     * PYTHIADEV-723
     * - Прокидываем _seqnum (при getSurvey _seqnum еще нет в стейте, поэтому может быть 1)
     * - Обновляем _seqnum в сторе
     */
    data._seqnum = _seqnum || 1;
    yield (0, effects_1.put)((0, actions_1.incrementSeqNum)());
    yield PENDING && (0, effects_1.put)({ type: PENDING });
    const location = yield (0, effects_1.select)(selectors_1.getLocation);
    const apiClient = yield (0, effects_1.call)(api_client_1.getClient, location);
    try {
        const res = yield (0, effects_1.call)(callApi, apiClient, method, params);
        if (res.status === 403 && ((_a = res.data) === null || _a === void 0 ? void 0 : _a.reason) === 'TEAM_AUTH_REQUIRED') {
            const searchParams = new URLSearchParams({ retpath: window.location.href });
            const href = `https://passport.yandex-team.ru/auth?${searchParams.toString()}`;
            return yield FAILURE &&
                (0, effects_1.put)({
                    type: 'REDIRECT',
                    url: href,
                });
        }
        if (res.status >= 300) {
            return yield FAILURE &&
                (0, effects_1.put)({
                    type: FAILURE,
                    status: res.status,
                    params,
                    data: res.data
                });
        }
        yield SUCCESS &&
            (0, effects_1.put)({
                type: SUCCESS,
                status: res.status,
                params,
                data: res.data
            });
    }
    catch (err) {
        yield FAILURE &&
            (0, effects_1.put)({
                type: FAILURE,
                status: getErrorCode(location.host),
                err
            });
        console.log(err);
    }
}
exports.handleRequest = handleRequest;
function* watchRequests() {
    const requestChannel = yield (0, effects_1.actionChannel)('REQUEST_TO_API');
    while (true) {
        // Ждем ivid для отправления событий
        yield (0, effects_1.call)(waitForInterviewId);
        const { method, params, steps } = yield (0, effects_1.take)(requestChannel);
        yield (0, effects_1.call)(handleRequest, { method, params, steps });
    }
}
exports.watchRequests = watchRequests;
