"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    /* PYTHIADEV-550 */
    .y-input_size_dependent.y-input .y-input__control {
        padding: 0.4em 0.53em;
        font-size: 1.5em;
        line-height: 1.4;
    }

    /* Стили для интупа внутри вопроса */
    .y-input_size_dependent .y-input__control {
        line-height: 2.4em;
    }
    .y-input_size_dependent .y-input__control,
    .y-input_size_dependent .y-input__hint {
        font-size: 1.5em !important;
        height: 2.4em !important;
    }
    .y-input_size_dependent .y-input__box:before {
        border-width: 0.2em !important;
    }

    .button_size_dependent {
        font-size: 1.7em;
    }

    .y-button_size_dependent {
        font-size: inherit;
        line-height: 3.2em;
        height: 3.2em;
    }
    .y-button_size_dependent .y-button__text {
        font-size: 1.5em;
        margin: 0 1em;
    }
    .y-button_size_dependent:before {
        border-width: 0.2em !important;
    }
`;
