"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.langDetect = exports.DEFAULT_LANG = void 0;
exports.DEFAULT_LANG = 'en';
function langDetect() {
    try {
        return navigator.language.toLowerCase().indexOf('ru') < 0 ? exports.DEFAULT_LANG : 'ru';
    }
    catch (_) {
        return exports.DEFAULT_LANG;
    }
}
exports.langDetect = langDetect;
