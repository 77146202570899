"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.checkAccessRedirect = exports.doRedirect = void 0;
const effects_1 = require("redux-saga/effects");
function doRedirect({ url, delay = 10 }) {
    try {
        setTimeout(() => {
            location.href = url;
        }, delay);
    }
    catch (_) { }
}
exports.doRedirect = doRedirect;
function* checkAccessRedirect(action) {
    const redirect = action.data && action.data.redirect;
    if (redirect) {
        yield (0, effects_1.call)(doRedirect, { url: redirect });
    }
}
exports.checkAccessRedirect = checkAccessRedirect;
function* router() {
    yield (0, effects_1.takeEvery)('SURVEY_GET_FAILURE', checkAccessRedirect);
    // @ts-expect-error TS(2769): No overload matches this call.
    yield (0, effects_1.takeEvery)('REDIRECT', doRedirect);
}
exports.router = router;
