"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    :root {
        --page-height: 100%;
    }

    html {
        height: var(--page-height);
    }
    body {
        margin: 0;
        height: var(--page-height);
    }
    .pythia-surveys-wrapper,
    .pythia-surveys-root {
        height: var(--page-height);
    }
`;
