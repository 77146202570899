"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.initFailure = exports.initSuccess = void 0;
const effects_1 = require("redux-saga/effects");
const actions_1 = require("../actions");
const display_hidden_questions_1 = require("./display-hidden-questions");
const selectors_1 = require("./selectors");
function* initSuccess(action) {
    const { data, params, status } = action;
    yield (0, effects_1.put)((0, actions_1.setInitedSurvey)(data, params, status));
    const demoMode = yield (0, effects_1.select)(selectors_1.getDemoMode);
    if (demoMode)
        yield (0, effects_1.call)(display_hidden_questions_1.setDisplayHiddenQuestions);
}
exports.initSuccess = initSuccess;
function* initFailure(action) {
    const { data, params, status } = action;
    yield (0, effects_1.put)((0, actions_1.setFailureSurvey)(data, params, status));
}
exports.initFailure = initFailure;
function* router() {
    yield (0, effects_1.takeEvery)('SURVEY_GET_SUCCESS', initSuccess);
    yield (0, effects_1.takeEvery)('SURVEY_GET_FAILURE', initFailure);
}
exports.router = router;
