"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileColorsStylesString(branding) {
    const { colors = {} } = branding;
    const { internal, main, border } = colors;
    if (!internal && !main && !border)
        return '';
    return `
        {
            ${main ? `
            --view-default-control-checked-background: ${main};
            --view-default-fill-color-action: ${main};
            --view-required-asterisk: ${main};
            ` : ''}
            ${internal ? `
            --view-default-fill-color-internal: ${internal};
            --view-default-text-action-color: ${internal};
            ` : ''}
            ${border ? `--view-default-control-color-border: ${border};` : ''}
        }
    `;
}
exports.default = compileColorsStylesString;
