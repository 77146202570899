"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGroupLabelStyle = void 0;
const groupColors = {
    1: '#d1d7f4',
    2: '#d7f4bd',
    3: '#f4d4bd',
    4: '#f4ebbd',
    5: '#b7d9f9',
    6: '#e0bdf4',
    7: '#bdecf4',
    8: '#f4bdd2',
    9: '#b8ddaa',
    10: '#f7e087'
};
const NUMBER_OF_COLORS = Object.keys(groupColors).length;
const getGroupLabelStyle = (index) => {
    let colorSelected = groupColors[(index + 1) % (NUMBER_OF_COLORS + 1)];
    if (index >= NUMBER_OF_COLORS) {
        colorSelected = groupColors[(index % NUMBER_OF_COLORS) + 1];
    }
    return {
        '--group-color': colorSelected
    };
};
exports.getGroupLabelStyle = getGroupLabelStyle;
