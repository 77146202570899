"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function reversePosition(pos) {
    if (pos === 'end')
        return 'start';
    if (pos === 'start')
        return 'end';
    return pos;
}
// eslint-disable-next-line complexity
function compileRatingStyleString(branding) {
    const { rating = {} } = branding;
    const { block: { count2maxWidth, count3maxWidth, innerPosition, maxWidth, position } = {}, borderWidth, focusVisible: { border: { color: focusVisibleBorderColor, } = {} } = {}, gap, colors: { borderActive, borderInactive, borderInactiveHover, fillActive, fillInactive, fillInactiveHover } = {} } = rating;
    return `
        {
            ${count2maxWidth ? `--rating-options-count-2-max-width: ${count2maxWidth};` : ''}
            ${count3maxWidth ? `--rating-options-count-3-max-width: ${count3maxWidth};` : ''}
            ${maxWidth ? `--rating-options-max-width: ${maxWidth};` : ''}
            ${position ? `--rating-options-position: ${position};` : ''}
            ${borderActive ? `--rating-border-active: ${borderActive};` : ''}
            ${borderInactive ? `--rating-border-inactive: ${borderInactive};` : ''}
            ${focusVisibleBorderColor ? `--rating-focus-visible-border-color: ${focusVisibleBorderColor};` : ''}
            ${borderInactiveHover ? `--rating-border-inactive-hover: ${borderInactiveHover};` : ''}
            ${fillActive ? `--rating-fill-active: ${fillActive};` : ''}
            ${fillInactive ? `--rating-fill-inactive: ${fillInactive};` : ''}
            ${fillInactiveHover ? `--rating-fill-inactive-hover: ${fillInactiveHover};` : ''}
            ${gap ? `--rating-gap: ${gap};` : ''}
            ${isNaN(Number(borderWidth)) ? '' : `--rating-stroke-width: ${borderWidth};`}
            ${innerPosition ? `--rating-justify-content: ${
    // Меняем end на start и наоборот, так как в css direction: rtl;
    // Костыляем из-за бага в старых андроидах
    // https://st.yandex-team.ru/PYTHIADEV-100
    reversePosition(innerPosition)};` : ''}
        }
    `;
}
exports.default = compileRatingStyleString;
