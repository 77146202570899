"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function compileErrorStyleString(branding) {
    const { error = {} } = branding;
    const { color, fontSize, lineHeight, margin } = error;
    return `
        {
            ${color ? `--error-color: ${color};` : ''}
            ${fontSize ? `--error-font-size: ${fontSize};` : ''}
            ${lineHeight ? `--error-line-height: ${lineHeight};` : ''}
            ${margin ? `--error-margin: ${margin};` : ''}
        }
    `;
}
exports.default = compileErrorStyleString;
