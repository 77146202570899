"use strict";
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.initMetrika = void 0;
const initMetrika = ({ counter }) => {
    (function (d, w, c) {
        (w[c] = w[c] || []).push(function () {
            try {
                w[`yaCounter${counter}`] = new Ya.Metrika2({
                    id: counter,
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true
                });
            }
            catch (e) { }
        });
        var n = d.getElementsByTagName('script')[0], s = d.createElement('script'), f = function () {
            n.parentNode.insertBefore(s, n);
        };
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://mc.yandex.ru/metrika/tag.js';
        // @ts-ignore
        if (w.opera == '[object Opera]') {
            d.addEventListener('DOMContentLoaded', f, false);
        }
        else {
            f();
        }
    })(document, window, 'yandex_metrika_callbacks2');
};
exports.initMetrika = initMetrika;
