"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    /* Все тексты для видео опросов белые */
    .app_theme_transparent .markdown *
    {
        text-align: center;
        color: #fff !important;
    }

    .app_theme_transparent .app__body
    {
        display: table-row;
    }

    .app_theme_transparent .final
    {
        color: #fff;
    }
`;
