"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .attachment-video {
        position: relative;
        height: 0;
        margin: 2.5em auto;
        padding-bottom: 51.25%;
        transition-property: margin;
        transition-duration: .5s;
        // PYTHIADEV-6006. Это ширина старого опросника, тк у толокеров проблемы с новой шириной при прохождение опросов.
        max-width: 600px;
    }

    .attachment-video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;
