"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STAR_CONFIGS = void 0;
;
exports.STAR_CONFIGS = {
    default: {
        few: {
            borderWidth: 2,
            width: 36,
            height: 38,
            path: 'M18 0L23.1733 10.8795L35.119 12.4377L26.3706 20.7198L28.5801' +
                ' 32.5623L18 26.8014L7.41987 32.5623L9.62938 20.7198L0.880983 12.4377L12.8267 10.8795L18 0Z',
            fillOpacity: 1
        }
    },
    portal: {
        few: {
            borderWidth: 2,
            width: 32,
            height: 31,
            path: 'M14.7315 2.14665C15.1984 1.06264 16.7354 1.06262 17.2022 2.14665L20.6637 10.1845L29.3778 ' +
                '10.9928C30.553 11.1018 31.028 12.5635 30.1413 13.3425L23.5664 19.1184L25.4906 27.6558C25.7501 ' +
                '28.8072 24.5067 29.7106 23.4918 29.108L15.9669 24.6398L8.44191 29.108C7.42708 29.7106 6.18362 ' +
                '28.8072 6.44312 27.6558L8.36728 19.1184L1.79244 13.3425C0.905748 12.5635 1.38068 11.1018 ' +
                '2.55592 10.9928L11.2701 10.1845L14.7315 2.14665Z',
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fillOpacity: 1
        },
        many: {
            borderWidth: 2,
            width: 24,
            height: 23,
            path: 'M11.0486 1.60986C11.3987 0.796856 12.5515 0.796839 12.9016 1.60986L15.4977 7.63829L22.0333 ' +
                '8.24445C22.9147 8.3262 23.271 9.42251 22.6059 10.0067L17.6748 14.3387L19.1179 20.7417C19.3126 ' +
                '21.6053 18.38 22.2828 17.6188 21.8309L11.9751 18.4797L6.3314 21.8309C5.57028 22.2828 4.63768 ' +
                '21.6053 4.83231 20.7417L6.27543 14.3387L1.3443 10.0067C0.67928 9.42252 1.03548 8.3262 1.91691 ' +
                '8.24445L8.45252 7.63829L11.0486 1.60986Z',
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fillOpacity: 1
        }
    }
};
