"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
function getTextColor({ view, checked }) {
    if (view === 'normal' && !checked) {
        return 'var(--button-normal-color)';
    }
    if (view === 'normal' && checked) {
        return 'var(--button-normal-color-checked)';
    }
    return 'var(--view-default-text-action-color)';
}
// eslint-disable-next-line complexity
function buttonCss(props) {
    const { size = 'xs', view = 'action', disabled, checked } = props;
    return (0, react_1.css) `
        align-items: center;
        background: ${checked
        ? `var(--button-${view}-selected-bg)`
        : `var(--button-${view}-bg)`};

        border-color: ${checked
        ? `var(--button-${view}-border-selected-color)`
        : `var(--button-${view}-border-color)`};

        border-radius: var(--button-${view}-border-radius);
        border-width: var(--button-${view}-border-width);

        color: ${getTextColor(props)};
        cursor: pointer;
        display: inline-flex;

        height: var(--button-${size}-height);
        justify-content: center;
        min-width: var(--button-${view}-min-width);

        opacity: 0.9;
        padding: var(--button-${size}-padding);
        text-align: center;
        transition-property: border-color, border-width, background;
        transition: 0.1s ease-out;
        white-space: nowrap;

        &:hover {
            opacity: 1;
            cursor: pointer;
            border-color: ${checked
        ? `var(--button-${view}-border-selected-color)`
        : `var(--button-${view}-border-hover-color)`};
            background: ${checked
        ? `var(--button-${view}-selected-hover-bg)`
        : `var(--button-${view}-hover-bg)`};
        }
        &:focus-visible {
            outline: 2px solid var(--button-focus-visible-border-color);
            outline-offset: 2px;
        }
        ${disabled && `
        background: var(--button-disabled-bg);
        border: 0;
        color: var(--button-disabled-text-color);

        .button__text {
            opacity: var(--button-disabled-text-opacity);
            color: var(--text-color);
        }

        &:hover {
            opacity: 0.9;
            cursor: default;
            background: var(--button-disabled-bg);
        }`}
        .button__text {
            font-family: var(--main-font);
            font-size: var(--button-${size}-font-size);
            line-height: var(--button-${size}-line-height);
            font-weight: var(--button-font-weight);
            margin: var(--button-${size}-text-margin);
        }
    `;
}
exports.default = buttonCss;
