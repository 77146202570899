"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SurveyPreset = exports.SurveyScope = void 0;
var SurveyScope;
(function (SurveyScope) {
    SurveyScope["Yandex"] = "yandex";
    SurveyScope["YandexNet"] = "yandexNet";
    SurveyScope["YandexTeam"] = "yandexTeam";
    SurveyScope["External"] = "external";
})(SurveyScope = exports.SurveyScope || (exports.SurveyScope = {}));
var SurveyPreset;
(function (SurveyPreset) {
    SurveyPreset["Simple"] = "simple";
    SurveyPreset["SimpleMarketAnalysis"] = "simpleMarketAnalysis";
    SurveyPreset["SimpleCompetitorAnalysis"] = "simpleCompetitorAnalysis";
    SurveyPreset["SimplePriceSensitivityMeter"] = "simplePriceSensitivityMeter";
    SurveyPreset["SimpleCustomerAnalytics"] = "simpleCustomerAnalytics";
    SurveyPreset["SimpleProductConceptTesting"] = "simpleProductConceptTesting";
    SurveyPreset["SimpleNameTesting"] = "simpleNameTesting";
    SurveyPreset["SimpleDesignEvaluation"] = "simpleDesignEvaluation";
    SurveyPreset["PRO"] = "pro";
    SurveyPreset["UX"] = "ux";
    SurveyPreset["CreateForMe"] = "createForMe";
    SurveyPreset["Creative"] = "creative";
    SurveyPreset["TestSlogans"] = "testSlogans";
    SurveyPreset["CompareSlogans"] = "compareSlogans";
    SurveyPreset["TestVideo"] = "testVideo";
    SurveyPreset["Bordomatik"] = "bordomatik";
    SurveyPreset["YandexAdScriptTest"] = "yandexAdScriptTest";
    SurveyPreset["YandexAdStoryboardTest"] = "yandexAdStoryBoardTest";
    SurveyPreset["YandexAdVideoTest"] = "yandexAdVideoTest";
    SurveyPreset["YandexConceptTest"] = "yandexConceptTest";
    SurveyPreset["YandexInterfaceMindfulness"] = "yandexInterfaceMindfulness";
    SurveyPreset["YandexMorda"] = "yandexMorda";
    SurveyPreset["YandexNameTest"] = "yandexNameTest";
    SurveyPreset["YandexSerpSurvey"] = "yandexSerpSurvey";
    SurveyPreset["YandexActiveFeedback"] = "yandexActiveFeedback";
    SurveyPreset["YandexPassiveFeedback"] = "yandexPassiveFeedback";
    SurveyPreset["CompetitorAnalysis"] = "competitorAnalysis";
    SurveyPreset["DemandAssessment"] = "demandAssessment";
    SurveyPreset["ConceptTest"] = "conceptTest";
    SurveyPreset["BannerTest"] = "bannerTest";
    SurveyPreset["Crowd"] = "crowd";
})(SurveyPreset = exports.SurveyPreset || (exports.SurveyPreset = {}));
